<template>
  <hide-show-card
    :title="`User ${profile.type} Profile`"
    v-if="profile !== undefined && profile !== null"
    :defaultOpen="true"
    id="profile"
  >
    <md-card-content
      class="md-gutter"
      v-if="profile !== undefined && profile !== null"
    >
      <div
        class="md-layout md-gutter"
        v-if="
          profile.images &&
            profile.images.thumbnail &&
            profile.images.thumbnail.url
        "
      >
        <div class="md-layout-item">
          <img :src="profile.images.thumbnail.url" alt="Avatar" width="100" />
        </div>
      </div>
      <div class="md-layout md-gutter">
        <div class="md-layout-item">
          {{ profile.title }} {{ profile.firstName }}
          {{ profile.lastName }}
        </div>
        <div class="md-layout-item">{{ profile.gender }}</div>
        <div class="md-layout-item">{{ profile.dateOfBirth }}</div>
      </div>
    </md-card-content>
    <md-card-content class="md-gutter" v-else>
      <span>User has no profile</span>
    </md-card-content>
  </hide-show-card>
</template>
<script>
import HideShowCard from "@/components/Layout/HideShowCard";
export default {
  name: "user-profile-view",
  props: ["profile"],
  components: {
    HideShowCard
  }
};
</script>
