import Vue from "vue";
import VueRouter from "vue-router";
import App from "@/App.vue";
import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/black-green-light.css";
import "firebaseui/dist/firebaseui.css";
import "@/assets/scss/clanbeat.scss";
import "@/components/Layout";

// router setup
import routes from "@/routes/routes";

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkExactActiveClass: "nav-item active"
});

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(VueMaterial);
Vue.use(require("vue-moment"));

import AuthListener from "@/services/api/AuthListener";
import UserProfileService from "./services/UserProfileService";

AuthListener.onReady(() => {
  new Vue({
    router,
    created() {
      var self = this;
      AuthListener.onChange(isAuthorized => {
        //        console.log("LOG IN: " + isAuthorized);
        //        console.log(self.$router);

        if (isAuthorized) {
          UserProfileService.getYourself()
            .then(response => {
              // profile exists
              if (self.$router.history.current.path == "/authorize") {
                self.$router.replace("/");
              }
            })
            .catch(error => {
              if (error.status == 404) {
                self.$router.replace("/profile");
              } else {
                if (self.$router.history.current.path == "/authorize") {
                  self.$router.replace("/");
                }
              }
            });
        } else {
          if (self.$router.history.current.path != "/authorize") {
            self.$router.replace("/authorize");
            /*
            self.$router.replace("authorize").catch(err => {
              console.error(err);
            });
             */
          }
        }
      });
    },
    el: "#app",
    render: h => h(App)
  });
});
