<template>
  <div>
    <HideShowCard title="Membership" id="membership">
      <md-card-content class="md-layout md-gutter">
        <cb-empty-state
          v-if="userMembership.length === 0 && !loading"
          :icon="$icons.membership"
          label="No membership available"
          description="There are no organisations and groups available."
        />
        <md-progress-bar
          class="md-accent"
          md-mode="indeterminate"
          v-if="loading"
        />
        <md-content
          v-for="organisation in userMembership"
          v-bind:key="organisation.id"
          class="md-elevation-2 md-layout-item md-xlarge-size-30 md-large-size-30 md-medium-size-48 md-small-size-100"
        >
          <md-subheader>
            <organisation-icon
              :tool-tip="organisation.name"
              :organisation-id="organisation.id"
            />
            <div class="md-list-item-text">
              <router-link :to="`/organisation/${organisation.id}`">{{
                organisation.name
              }}</router-link>
            </div>
          </md-subheader>
          <membership-checkbox
            prefix="organisation"
            class="md-list-action"
            :data="organisation"
            @onRolesChanged="
              role => onOrganisationRoleChanged(organisation, role)
            "
          />
          <md-content
            v-for="group in organisation.groups"
            v-bind:key="group.id"
          >
            <md-divider></md-divider>
            <md-subheader>
              <md-avatar>
                <md-icon v-if="group.isolated"
                  >error_outline<md-tooltip
                    >This group is isolated and is not verified in
                    organisation</md-tooltip
                  ></md-icon
                >
                <group-icon
                  tool-tip="This group is not isolated and is part of organisation"
                  :group-id="group.id"
                  v-else
                />
              </md-avatar>
              <div class="md-list-item-text">
                <router-link
                  :to="`/organisation/${organisation.id}/group/${group.id}`"
                  >{{ group.name }}</router-link
                >
              </div>
            </md-subheader>
            <membership-checkbox
              class="md-list-action"
              prefix="group"
              :data="group"
              @onRolesChanged="role => onGroupRoleChanged(group, role)"
            />
          </md-content>
        </md-content>
      </md-card-content>
    </HideShowCard>
    <hide-show-card title="Other organisations" id="other_org">
      <search-input
        label="Filter organisations"
        @onSearchChange="onOrganisationSearch"
      />
      <md-card-content>
        <md-list class="md-dense">
          <md-list-item
            v-for="organisation in otherOrganisations"
            v-bind:key="organisation.id"
          >
            <organisation-icon
              :tool-tip="organisation.name"
              :organisation-id="organisation.id"
            /><span class="md-list-item-text"
              ><router-link :to="`/organisation/${organisation.id}`">{{
                organisation.name
              }}</router-link></span
            ><md-button
              class="md-dense"
              :disabled="membershipIdList.includes(organisation.id)"
              v-on:click="addUserToOrganisation(organisation.id)"
              >Add organisation</md-button
            ></md-list-item
          >
        </md-list>
      </md-card-content>
      <md-card-actions>
        <md-button
          :disabled="!paginator.hasPrevious"
          v-on:click="onPreviousClick"
          >Previous</md-button
        >
        <md-button :disabled="!paginator.hasNext" v-on:click="onNextClick"
          >Next</md-button
        >
      </md-card-actions>
    </hide-show-card>
  </div>
</template>
<script>
import UserMembershipService from "@/services/UserMembershipService";
import MembershipCheckbox from "./MembershipCheckbox";
import SearchInput from "@/components/Layout/SearchInput";
import HideShowCard from "@/components/Layout/HideShowCard";

export default {
  name: "user-membership",
  components: {
    MembershipCheckbox,
    SearchInput,
    HideShowCard
  },
  props: {
    userId: Number
  },
  data() {
    return {
      loading: false,
      organisationsIterator: null,
      userMembership: [],
      membershipIdList: [],
      paginator: { hasNext: false, hasPrevious: false },
      otherOrganisations: []
    };
  },
  methods: {
    setMembership: function(membership) {
      this.userMembership = membership;
      this.membershipIdList = this.userMembership.map(org => org.id);
    },
    addUserToOrganisation: async function(organisationId) {
      const result = await UserMembershipService.addUserRoleToOrganisation(
        organisationId,
        this.userId
      );
      this.setMembership(result.result);
    },
    onOrganisationRoleChanged: async function(organisation, role) {
      if (organisation.roles.includes(role)) {
        const result = await UserMembershipService.removeUserRoleFromOrganisation(
          organisation.id,
          this.userId,
          role
        );
        this.setMembership(result.result);
      } else {
        const result = await UserMembershipService.addUserRoleToOrganisation(
          organisation.id,
          this.userId,
          role
        );
        this.setMembership(result.result);
      }
    },
    onGroupRoleChanged: async function(group, role) {
      if (group.roles.includes(role)) {
        const result = await UserMembershipService.removeUserRoleFromGroup(
          group.id,
          this.userId,
          role
        );
        this.setMembership(result.result);
      } else {
        const result = await UserMembershipService.addUserRoleToGroup(
          group.id,
          this.userId,
          role
        );
        this.setMembership(result.result);
      }
    },
    checkListPaginator: async function() {
      const hasNext = await this.organisationsIterator.hasNext();
      const hasPrevious = await this.organisationsIterator.hasPrevious();
      this.paginator = { hasNext: hasNext, hasPrevious: hasPrevious };
    },
    onOrganisationSearch: async function(searchQuery) {
      this.paginator = { hasNext: false, hasPrevious: false };
      try {
        await this.organisationsIterator.setQueryParameter(
          "query",
          searchQuery
        );
        await this.onNextClick();
      } catch (error) {
        console.error(error);
      }
    },
    onAddItem: function() {
      this.$router.push({
        name: "Add User"
      });
    },
    onPreviousClick: async function() {
      this.paginator = { hasNext: false, hasPrevious: false };
      try {
        this.otherOrganisations = await this.organisationsIterator.getPrevious();
        await this.checkListPaginator();
      } catch (error) {
        console.error(error);
      } finally {
      }
    },
    onNextClick: async function() {
      this.paginator = { hasNext: false, hasPrevious: false };
      try {
        this.otherOrganisations = await this.organisationsIterator.getNext();
        await this.checkListPaginator();
      } catch (error) {
        console.error(error);
      }
    },
    fetchData: async function() {
      this.loading = true;
      if (this.userId == null) {
        return;
      }
      try {
        this.organisationsIterator = UserMembershipService.getOrganisationListIterator();
        this.onNextClick();
        const membership = await UserMembershipService.getUserMembership(
          this.userId
        );
        this.setMembership(membership.result);
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    }
  },
  created: function() {
    this.fetchData();
  },
  watch: {
    $route: "fetchData",
    $props: {
      handler() {
        this.fetchData();
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
