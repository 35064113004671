<template>
  <md-button
    v-if="group != null && group.id != null"
    :to="{ name: 'List group users', params: { group_id: group.id } }"
    >Users</md-button
  >
</template>
<script>
export default { name: "group-user-list-button", props: ["group"] };
</script>
