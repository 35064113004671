<template>
  <div>
    <md-table v-model="users" class="users-list">
      <md-table-row
        slot="md-table-row"
        slot-scope="{ item, index }"
        v-on:click="goToUser(item, index)"
        v-bind:class="className(item)"
      >
        <md-table-cell class="icon">
          <md-tooltip>{{ getIconTooltip(item) }}</md-tooltip>
          <md-icon v-for="role in filterRole(item.roles)" :key="role">{{
            getIcon(role)
          }}</md-icon></md-table-cell
        >
        <md-table-cell md-label="Name" width="100%">
          <img
            v-if="item.thumbnailUrl != null"
            :src="item.thumbnailUrl"
            :alt="item.name"
            class="list-avatar"
          />
          <router-link
            :to="`/organisation/${organisation.id}/user/${item.id}`"
            v-if="item.id && organisation && organisation.id"
            >{{ item.name }}</router-link
          >
          <router-link
            :to="`/group/${group.id}/user/${item.id}`"
            v-else-if="item.id && group && group.id"
            >{{ item.name }}</router-link
          >
          <router-link :to="`/user/${item.id}`" v-else-if="item.id">{{
            item.name
          }}</router-link>
          <span v-else>{{ item.name }}</span>
        </md-table-cell>
        <md-table-cell md-label="Created">
          <show-date-time :date="item.createdAt" />
        </md-table-cell>
        <md-table-cell md-label="Disabled" v-if="item.disabledAt != null">
          <show-date-time :date="item.disabledAt"
        /></md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import TranslationService from "@/services/TranslationService";

export default {
  name: "user-list",
  props: ["users", "group", "organisation"],
  methods: {
    goToUser(item) {
      if (this.group != null && this.group.id != null) {
        this.$router.push({
          name: "Edit Group User",
          params: { user_id: item.id, group_id: this.group.id }
        });
      } else if (this.organisation != null && this.organisation.id != null) {
        this.$router.push({
          name: "Edit Organisation User",
          params: { user_id: item.id, organisation_id: this.organisation.id }
        });
      } else {
        this.$router.push({
          name: "Edit User",
          params: { user_id: item.id }
        });
      }
    },
    filterRole(roles) {
      if (roles == null) return [];
      if (roles.length <= 1) return roles;
      if (roles.includes("ROLE_MODERATOR")) {
        return roles.filter(e => e !== "ROLE_MEMBER");
      }
      return roles;
    },
    getIcon(role) {
      switch (role) {
        case "ROLE_SUPERUSER":
          return this.$icons.account_status.superuser;
        case "ROLE_ADMINISTRATOR":
          return this.$icons.account_status.administrator;
        case "ROLE_MODERATOR":
          return this.$icons.account_status.teacher;
        case "ROLE_MEMBER":
          return this.$icons.account_status.member;
      }
    },
    getIconTooltip(item) {
      const result = [];
      if (item.disabledAt != null) {
        result.push("disabled");
      }
      item.roles
        .map(role => TranslationService.getTranslation(role))
        .forEach(role => result.push(role));
      return result.join(", ");
    },
    className(item) {
      const result = [];
      if (item.disabledAt != null) {
        result.push("disabled-account");
      }
      if (item.disabledAt != null) {
        result.push("user-disabled");
      }
      if (item.deletedAt != null) {
        result.push("user-deleted");
      }
      return result.join(" ");
    }
  }
};
</script>
