import Api from "@/services/api/RestAPI";
import { PaginatedStreamDataIterator } from "@/services/api/ResultDataIterator";

export default {
  getOrganisationListIterator() {
    return new PaginatedStreamDataIterator("/api/v2/organisations");
  },
  get(organisation_id) {
    return Api.get(`/api/organisation/v1/${organisation_id}`);
  },
  save(organisation_id, data) {
    if (organisation_id) {
      return Api.put(`/api/organisation/v1/${organisation_id}`, data);
    } else {
      return Api.post("/api/organisation/v1", data);
    }
  },
  delete(organisation_id) {
    return Api.delete(`/api/organisation/v1/${organisation_id}`);
  },
  getOrganisationName(organisation, suffix, defaultValue) {
    if (organisation == null) {
      return defaultValue;
    }
    if (
      organisation.type !== null &&
      organisation.type !== undefined &&
      organisation.type !== "" &&
      organisation.name !== null &&
      organisation.name !== undefined &&
      organisation.name !== ""
    ) {
      return organisation.type + " " + organisation.name + suffix;
    } else if (
      organisation.type !== null &&
      organisation.type !== undefined &&
      organisation.type !== ""
    ) {
      return organisation.type + suffix;
    } else if (
      organisation.name !== null &&
      organisation.name !== undefined &&
      organisation.name !== ""
    ) {
      return organisation.name + suffix;
    }
    return defaultValue;
  },
  saveImage(organisation_id, image) {
    return Api.post("/api/organisation/v1/" + organisation_id + "/image", {
      image: image
    });
  },
  getImages: function(organisation_id) {
    return Api.getImage(`/api/organisation/v1/${organisation_id}/images`);
  },
  getFullImageUrl: async function(organisation_id) {
    const images = await this.getImages(organisation_id);
    if (images && images.full && images.full.url) {
      return images.full.url;
    }
    return null;
  },
  getThumbnailImageUrl: async function(organisation_id) {
    const images = await this.getImages(organisation_id);
    if (images && images.thumbnail && images.thumbnail.url) {
      return images.thumbnail.url;
    }
  }
};
