<template>
  <user-list-component
    :users="list"
    :loading="loading"
    :organisation="organisation"
    :has-next-page="paginator.hasNext"
    :has-previous-page="paginator.hasPrevious"
    @onPreviousClick="onPreviousClick()"
    @onNextClick="onNextClick()"
    result-error="resultError"
    @addItem="onAddItem()"
    @onUserSearch="onUserSearch"
  />
</template>
<script>
import UserService from "@/services/UserService";
import OrganisationService from "@/services/OrganisationService";
import UserListComponent from "./Shared/UsersListComponent";

export default {
  name: "user-list",
  props: ["organisation_id"],
  components: {
    UserListComponent
  },
  data() {
    return {
      organisation: {},
      loading: true,
      list: [],
      resultError: null,
      paginator: { hasNext: false, hasPrevious: false },
      userListIterator: null
    };
  },
  methods: {
    checkListPaginator: async function() {
      const hasNext = await this.userListIterator.hasNext();
      const hasPrevious = await this.userListIterator.hasPrevious();
      this.paginator = { hasNext: hasNext, hasPrevious: hasPrevious };
    },
    onUserSearch: async function(searchQuery) {
      this.paginator = { hasNext: false, hasPrevious: false };
      this.loading = true;
      try {
        await this.userListIterator.setQueryParameter("query", searchQuery);
        await this.onNextClick();
      } catch (error) {
        console.log(error);
        this.resultError = error;
      } finally {
        this.loading = false;
      }
    },
    onAddItem: function() {
      this.$router.push({
        name: "New Organisation User",
        params: { organisation_id: this.organisation_id }
      });
    },
    onPreviousClick: async function() {
      this.paginator = { hasNext: false, hasPrevious: false };
      this.loading = true;
      try {
        this.list = await this.userListIterator.getPrevious();
        await this.checkListPaginator();
      } catch (error) {
        this.resultError = error;
      } finally {
        this.loading = false;
      }
    },
    onNextClick: async function() {
      this.paginator = { hasNext: false, hasPrevious: false };
      this.loading = true;
      try {
        this.list = await this.userListIterator.getNext();
        await this.checkListPaginator();
      } catch (error) {
        this.resultError = error;
      } finally {
        this.loading = false;
      }
    }
  },
  created: async function() {
    this.list = [];

    this.loading = true;
    try {
      const orgResult = await OrganisationService.get(this.organisation_id);
      this.organisation = orgResult.result;
      this.userListIterator = UserService.getListInOrganisationIterator(
        this.organisation.id
      );
      await this.onNextClick();
    } catch (error) {
      this.resultError = error;
    } finally {
      this.loading = false;
    }
  }
};
</script>
