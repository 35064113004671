const translations = {
  ROLE_SUPERUSER: "Super User",
  ROLE_ADMINISTRATOR: "Admin",
  ROLE_MODERATOR: "Moderator (Legacy)",
  ROLE_LEAD: "Lead",
  ROLE_TEACHER: "Teacher",
  ROLE_STAFF: "Staff",
  ROLE_SUPPORT: "Support",
  ROLE_MEMBER: "Member",

  ROLE_ADMINISTRATOR_tooltip:
    "Owner is a ROLE_ADMINISTRATOR who has power over the school and all its classes",

  organisation_ROLE_MODERATOR_tooltip:
    "Teacher is a ROLE_MODERATOR role who is automatically assigned as a teacher in every classes in this school",

  group_ROLE_MEMBER_tooltip: "Member of the class has access to the class data",

  group_ROLE_MODERATOR_tooltip:
    "Teacher is a ROLE_MODERATOR role and can manage this class"
};

export default {
  getTranslation: function(label) {
    if (label === null || !translations[label]) return label;
    return translations[label];
  }
};
