<template>
  <md-avatar v-if="thumbnail_url != null">
    <img :src="thumbnail_url" :alt="toolTip" />
    <md-tooltip>{{ toolTip }}</md-tooltip>
  </md-avatar>
  <md-avatar v-else class="organisation-icon"
    ><md-icon>{{ $icons.organisation }}</md-icon
    ><md-tooltip>{{ toolTip }}</md-tooltip></md-avatar
  >
</template>
<script>
import OrganisationService from "@/services/OrganisationService";
export default {
  name: "organisation-icon",
  props: {
    toolTip: {
      type: String,
      default: "Organisation"
    },
    organisationId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      thumbnail_url: null
    };
  },
  created: async function() {
    this.thumbnail_url = null;
    if (this.organisationId == null) {
      return;
    }
    this.thumbnail_url = await OrganisationService.getThumbnailImageUrl(
      this.organisationId
    );
  }
};
</script>
