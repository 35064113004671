<template>
  <md-card-content class="md-primary">
    <div class="md-layout md-gutter">
      <div
        class="md-layout-item md-alignment-top-left md-xlarge-size-100 md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
      >
        <cb-image-upload
          @onImageChange="item => $emit('onImageChange', item)"
          :image-url="imageUrl"
        />
      </div>
    </div>
    <div class="md-layout md-gutter">
      <div
        class="md-title md-layout-item md-alignment-top-left md-medium-size-50 md-small-size-60 md-xsmall-size-100 md-xlarge-size-30 md-large-size-30"
      >
        Colors
      </div>
    </div>
    <div class="md-layout md-gutter">
      <div
        class="md-layout-item md-alignment-top-left md-medium-size-25 md-small-size-30 md-xsmall-size-100 md-xlarge-size-15 md-large-size-15"
      >
        <md-field>
          <label>Main background</label>
          <md-input v-model="edit.mainBackgroundColor" />
        </md-field>
      </div>
      <div
        class="md-layout-item md-alignment-top-left md-medium-size-25 md-small-size-30 md-xsmall-size-100 md-xlarge-size-15 md-large-size-15"
      >
        <md-field>
          <label>Main foreground</label>
          <md-input v-model="edit.mainForegroundColor" />
        </md-field>
      </div>
    </div>
  </md-card-content>
</template>
<script>
export default {
  name: "organisation-style-edit",
  props: ["edit", "imageUrl"]
};
</script>
