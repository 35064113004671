<template>
  <div class="roles" v-if="data != null && roles != null && roles.length > 0">
    <span v-bind:key="role" v-for="role in roles">
      <md-checkbox
        v-model="selectedRoles"
        :value="role"
        v-on:change="onRoleChange(role)"
        :disabled="
          prefix === 'organisation' && isolated && !selectedRoles.includes(role)
        "
      >
        <md-tooltip>{{ getLabel(role + "_tooltip") }}</md-tooltip>
        <md-icon class="group-icon">{{ getIcon(role) }}</md-icon>
        {{ getLabel(role) }}
      </md-checkbox>
    </span>
  </div>
</template>
<script>
import TranslationService from "@/services/TranslationService";

export default {
  name: "membership-checkbox",
  props: {
    data: {
      type: Object
    },
    prefix: {
      type: String
    }
  },
  data: () => ({
    roles: [],
    isolated: false,
    selectedRoles: [],
    availableRoles: []
  }),
  methods: {
    onRoleChange: function(role) {
      if (this.isolated && this.prefix === "group") {
        const result = confirm(
          "Are you sure you would like to change this isolated group?\nThis might become irreversable!"
        );
        if (!result) {
          const index = this.selectedRoles.indexOf(role);
          if (index < 0) {
            this.selectedRoles.push(role);
          } else {
            this.selectedRoles.splice(index, 1);
          }
          return false;
        }
      }
      this.$emit("onRolesChanged", role);
    },
    getIcon: function(role) {
      return this.$icons.getAccountStatusIconByRole(role);
    },
    getLabel: function(role) {
      const translatable = this.prefix + "_" + role;
      const result = TranslationService.getTranslation(translatable);
      if (result === translatable) {
        return TranslationService.getTranslation(role);
      }
      return result;
    },
    fixRoles() {
      this.roles = [];
      this.selectedRoles = [];
      this.availableRoles = [];
      let isolated = false;

      if (
        this.data.isolated === undefined ||
        (this.data.isolated === null && this.data.groups)
      ) {
        this.data.groups
          .filter(g => g.roles && g.roles.length > 0 && g.isolated === true)
          .some(() => (isolated = true));
      } else {
        isolated = this.data.isolated === true;
      }
      this.isolated = isolated;
      if (this.data.availableRoles != null) {
        this.data.availableRoles.forEach(role => {
          this.roles.push(role);
          this.availableRoles.push(role);
        });
      }
      if (this.data.roles != null) {
        this.data.roles.forEach(role => {
          this.selectedRoles.push(role);
          if (!this.roles.includes(role)) {
            this.roles.push(role);
          }
        });
      }
    }
  },
  /*  watch: {
    data: function() {
      this.fixRoles();
    }
  },*/
  created: function() {
    this.fixRoles();
  }
};
</script>
