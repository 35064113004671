<template>
  <md-button
    :disabled="disabled"
    class="md-icon-button"
    @click="$emit('click')"
  >
    <delete-icon :tool-tip="toolTip" />
  </md-button>
</template>
<script>
export default {
  name: "delete-button",
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default() {
        return false;
      }
    },
    toolTip: {
      type: String,
      required: true
    }
  }
};
</script>
