<template>
  <div v-if="languages != null && languages != null && languages.length > 0">
    <span v-bind:key="lang.key" v-for="lang in languages">
      <md-checkbox
        v-model="selectedLanguage"
        :value="lang.key"
        v-on:change="$emit('onLanguagesChanged', lang)"
      >
        {{ lang.label }}
      </md-checkbox>
    </span>
  </div>
</template>
<script>
import ConstantService from "@/services/ConstantService";

export default {
  name: "languages-choice",
  props: ["language"],
  data: function() {
    return {
      languages: [],
      selectedLanguage: null
    };
  },

  created: async function() {
    const languages = await ConstantService.listLanguages();
    this.languages = languages.languages;
    if (this.language === undefined || this.language === null) {
      this.selectedLanguage = this.languages.find(
        e => e.label === languages.default
      ).key;
      this.$emit("onLanguagesChanged", this.selectedLanguage);
    } else {
      this.selectedLanguage = this.language.key;
    }
  }
};
</script>
