import Firebase from "firebase/app";
import "firebase/auth";

Firebase.initializeApp({
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN
});

import * as FirebaseUi from "firebaseui";
var localFirebaseUI = new FirebaseUi.auth.AuthUI(Firebase.auth());

export default {
  firebaseui: localFirebaseUI,
  onAuthStateChanged: callback => {
    Firebase.auth().onAuthStateChanged(user => {
      if (user) {
        if (user.email != null && !user.emailVerified) {
          user.sendEmailVerification().then(function() {});
        }
        callback(true, user);
      } else {
        callback(false);
      }
    });
  },
  logout: callback => {
    Firebase.auth()
      .signOut()
      .then(
        () => {
          callback(true);
        },
        error => {
          callback(false, error);
        }
      );
  },
  signInOptions: [
    Firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    Firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    // firebase.auth.GithubAuthProvider.PROVIDER_ID,
    Firebase.auth.EmailAuthProvider.PROVIDER_ID
  ]
};
