<template>
  <md-menu-item
    v-if="group != null && group.id != null"
    :to="{ name: 'List group users', params: { group_id: group.id } }"
  >
    <span>Users</span>
    <users-icon />
  </md-menu-item>
</template>
<script>
export default { name: "group-user-list-menu-item", props: ["group"] };
</script>
