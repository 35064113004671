<template>
  <div>
    <md-table
      v-model="groups"
      md-sort="name"
      md-sort-order="asc"
      class="item-list"
    >
      <md-table-row
        slot="md-table-row"
        slot-scope="{ item, index }"
        v-on:click="goToGroup(item, index)"
      >
        <md-table-cell md-label="Type" md-sort-by="type"
          >{{ item.type }}
        </md-table-cell>
        <md-table-cell md-label="Name" md-sort-by="name" width="100%">
          <div class="md-layout md-gutter">
            <md-icon v-if="item.partOfOrganisation"
              >verified<md-tooltip
                >This group is part of the organisation</md-tooltip
              ></md-icon
            ><md-icon v-else
              >error_outline<md-tooltip
                >This group is not verified in organisation</md-tooltip
              ></md-icon
            >
            <span class="md-layout-item md-alignment-top-left"
              ><router-link
                :to="`/organisation/${item.organisationId}/group/${item.id}`"
                >{{ item.name }}</router-link
              ></span
            >
            <paid-and-trial-in-list :item="item" />
            <created-at-in-list :item="item" />
          </div>
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import PaidAndTrialInList from "@/components/Layout/PaidAndTrialInList";
import CreatedAtInList from "@/components/Layout/CreatedAndUpdatedList";
export default {
  name: "group-list",
  components: { CreatedAtInList, PaidAndTrialInList },
  props: ["groups"],
  methods: {
    goToGroup(item) {
      this.$router.push({
        name: "Edit Group",
        params: { group_id: item.id, organisation_id: item.organisationId }
      });
    }
  }
};
</script>
