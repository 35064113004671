<template>
  <md-list>
    <md-list-item to="/dashboard">
      <home-icon />
      <span class="md-list-item-text">Dashboard</span>
    </md-list-item>

    <md-list-item to="/users">
      <users-icon />
      <span class="md-list-item-text">All users</span>
    </md-list-item>

    <md-list-item to="/organisations">
      <organisation-icon />
      <span class="md-list-item-text">Organisations</span>
    </md-list-item>

    <md-divider v-if="hasOrganisation" class="drawer-divider" />

    <list-item-params-link
      :text="organisationName"
      link="Edit Organisation"
      :organisation="organisation"
    >
      <organisation-icon
        :tool-tip="organisationName"
        :organisation-id="organisationId"
      />
    </list-item-params-link>

    <list-item-params-link
      text="Organisation users"
      link="List organisation users"
      :organisation="organisation"
    >
      <organisation-users-icon />
    </list-item-params-link>

    <list-item-params-link
      text="Groups"
      link="List Groups"
      :organisation="organisation"
    >
      <group-icon />
    </list-item-params-link>

    <md-divider v-if="hasGroup" class="drawer-divider" />

    <list-item-params-link
      :text="groupNameForEdit"
      link="Edit Group"
      :group="group"
      :organisation="organisation"
    >
      <group-icon />
    </list-item-params-link>

    <list-item-params-link
      text="Group users"
      link="List group users"
      :group="group"
    >
      <group-users-icon />
    </list-item-params-link>

    <md-divider class="drawer-divider" />

    <md-list-item to="/interests_bucket_list" v-if="isSuperUser()">
      <md-icon class="interests-bucket-list"
        >{{ $icons.lists
        }}<md-tooltip>Interests & Bucket list</md-tooltip></md-icon
      >
      <span class="md-list-item-text">Interests & Bucket list</span>
    </md-list-item>

    <md-list-item to="/auto-questions" v-if="isSuperUser()">
      <md-icon class="auto-questions"
        >{{ $icons.autoQuestions
        }}<md-tooltip>Auto questions management</md-tooltip></md-icon
      >
      <span class="md-list-item-text">Auto questions</span>
    </md-list-item>
  </md-list>
</template>
<script>
import ListItemParamsLink from "./ListItemParamsLink";
import RestAPI from "@/services/api/RestAPI";
export default {
  props: ["organisation", "group"],
  components: {
    ListItemParamsLink
  },
  methods: {
    isSuperUser() {
      return RestAPI.hasRole("SUPERUSER");
    }
  },
  computed: {
    groupNameForEdit: function() {
      if (this.hasGroup && this.hasOrganisation) {
        return this.group.name;
      }
      return null;
    },
    organisationName: function() {
      if (this.hasOrganisation) {
        return this.organisation.name;
      }
      return null;
    },
    organisationId: function() {
      if (this.hasOrganisation) {
        return this.organisation.id;
      }
      return null;
    },
    hasOrganisation: function() {
      return this.organisation != null && this.organisation.id != null;
    },
    hasGroup: function() {
      return this.group != null && this.group.id != null;
    }
  },
  name: "main-app-drawer-menu"
};
</script>
<style lang="scss" scoped></style>
