<template>
  <md-list class="md-inset checkin-question-item">
    <md-list-item v-for="question in questions" :key="question.id">
      <div class="md-list-item-text">
        <textarea v-model="question.question"></textarea>
      </div>
      <md-button class="md-icon-button" @click="changeItem(question)">
        <done-icon tool-tip="Add new check in group" />
      </md-button>
      <enable-disable-button
        tool-tip-disabled="Enable question"
        tool-tip-enabled="Disable question"
        :value="question.enabled"
        @click="enableItem(question.id, !question.enabled)"
      />
      <delete-button
        tool-tip="Remove question"
        @click="removeItem(question.id)"
      />
    </md-list-item>
    <md-list-item>
      <md-field>
        <label>Add new question</label>
        <md-textarea v-model="newQuestion"></md-textarea>
      </md-field>
      <md-button class="md-icon-button" @click="addItem()">
        <add-icon tool-tip="Add new check in group" />
      </md-button>
    </md-list-item>
  </md-list>
</template>

<script>
export default {
  name: "check-in-questions",
  props: ["questions"],
  data() {
    return {
      newQuestion: null
    };
  },
  methods: {
    enableItem: function(id, enabled) {
      this.$emit("onCheckInQuestionEnabled", { id: id, enabled: enabled });
    },
    removeItem: function(id) {
      this.$emit("onCheckInQuestionRemoved", id);
    },
    addItem: function() {
      this.$emit("onCheckInQuestionAdded", this.newQuestion);
      this.newQuestion = null;
    },
    changeItem: function(item) {
      this.$emit("onCheckInQuestionChanged", item);
    }
  }
};
</script>

<style lang="scss" scoped>
.checkin-question-item .checkin-question {
  white-space: normal;
}
</style>
