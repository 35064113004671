<template>
  <md-menu-item
    v-if="organisation != null && organisation.id != null"
    :to="{
      name: 'List organisation users',
      params: { group_id: organisation.id }
    }"
  >
    <span>Users</span>
    <users-icon />
  </md-menu-item>
</template>
<script>
export default {
  name: "organisation-user-list-menu-item",
  props: ["organisation"]
};
</script>
