<template>
  <md-button
    v-if="organisation != null && organisation.id != null"
    :to="{
      name: 'List organisation users',
      params: { organisation_id: organisation.id }
    }"
    >Users</md-button
  >
</template>
<script>
export default {
  name: "organisation-user-list-button",
  props: ["organisation"]
};
</script>
