<template>
  <main-app-container
    :title="titleName"
    add_text="New group"
    :icon="$icons.group"
    @addItem="onAddClicked()"
    :organisation="organisation"
  >
    <cb-empty-state
      v-if="list.length === 0 && !loading"
      :icon="$icons.group"
      label="Create first group"
      description="There is nothing to do without any group"
      button="Create new group"
      @addItem="onAddClicked()"
    />
    <form-errors :resultError="resultError" />
    <md-card v-if="list.length > 0">
      <md-card-header>
        <md-card-header-text>
          <div class="md-title"></div>
        </md-card-header-text>
        <md-menu md-size="big" md-direction="bottom-end">
          <md-button class="md-icon-button" md-menu-trigger>
            <menu-dropdown-icon />
          </md-button>

          <md-menu-content>
            <organisation-menu-item :organisation="organisation" />
            <organisation-user-list-menu-item :organisation="organisation" />
            <group-list-menu-item :organisation="organisation" />
          </md-menu-content>
        </md-menu>
      </md-card-header>
      <md-card-content>
        <group-list :groups="list" />
      </md-card-content>
    </md-card>
  </main-app-container>
</template>
<script>
import { GroupList } from "@/components/Group/";
import OrganisationService from "@/services/OrganisationService";
import GroupService from "@/services/GroupService";

export default {
  name: "groups-list",
  props: ["organisation_id"],
  components: {
    GroupList
  },
  data() {
    return {
      loading: true,
      organisation: {},
      list: [],
      resultError: null
    };
  },
  computed: {
    titleName: function() {
      return this.organisation.type + " " + this.organisation.name + " groups";
    }
  },
  methods: {
    onAddClicked: function() {
      this.$router.push({
        name: "Add Group",
        params: { organisation_id: this.organisation_id }
      });
    }
  },
  created: async function() {
    this.list = [];
    this.loading = true;
    try {
      this.organisation = await OrganisationService.get(
        this.organisation_id
      ).then(e => e.result);
      this.list = await GroupService.listInOrganisations(
        this.organisation_id
      ).then(result => result.result);
    } catch (error) {
      this.resultError = error;
    } finally {
      this.loading = false;
    }
  }
};
</script>
