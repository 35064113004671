import Api from "./api/RestAPI";
import RestAPI from "./api/RestAPI";

export default {
  list() {
    if (!RestAPI.hasRole("SUPERUSER")) throw new Error("Not superuser!");
    return Api.get("/api/apikey/v1");
  },
  getScopes() {
    if (!RestAPI.hasRole("SUPERUSER")) throw new Error("Not superuser!");
    return Api.get("/api/apikey/v1/scopes");
  },
  deleteApiKey(id) {
    if (!RestAPI.hasRole("SUPERUSER")) throw new Error("Not superuser!");
    return Api.delete(`/api/apikey/v1/${id}`);
  },
  generateApiKey(scopes) {
    if (!RestAPI.hasRole("SUPERUSER")) throw new Error("Not superuser!");
    return Api.post("/api/apikey/v1", {scopes: scopes});
  }
};
