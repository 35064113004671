<template>
  <user-edit-component :user_id="user_id" :organisation="organisation" />
</template>
<script>
import UserEditComponent from "./Shared/UserEditComponent";
import OrganisationService from "@/services/OrganisationService";

export default {
  name: "user-edit-page",
  props: ["user_id", "organisation_id"],
  components: {
    UserEditComponent
  },
  data() {
    return {
      organisation: {}
    };
  },
  created: async function() {
    try {
      const result = await OrganisationService.get(this.organisation_id);
      this.organisation = result.result;
    } catch (error) {
      this.resultError = error;
    }
  }
};
</script>
