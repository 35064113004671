<template>
  <md-card>
    <md-card-header>Profile</md-card-header>
    <md-card-content class="md-primary md-layout md-gutter">
      <div class="md-layout-item md-alignment-top-left md-size-100">
        <label v-if="user.profile != null && user.profile.type != null">{{
          user.profile.type
        }}</label>
      </div>
      <div
        class="md-layout-item md-alignment-top-left md-medium-size-20 md-small-size-20 md-xsmall-size-100"
      >
        <md-field>
          <label>Name prefix</label>
          <md-input disabled="disabled" v-model="user.namePrefix" />
        </md-field>
      </div>
      <div
        class="md-layout-item md-alignment-top-left md-medium-size-40 md-small-size-40 md-xsmall-size-100"
      >
        <md-field>
          <label>First name</label>
          <md-input disabled="disabled" v-model="user.firstName" />
        </md-field>
      </div>
      <div
        class="md-layout-item md-alignment-top-left md-medium-size-40 md-small-size-40 md-xsmall-size-100"
      >
        <md-field>
          <label>Last name</label>
          <md-input disabled="disabled" v-model="user.lastName" />
        </md-field>
      </div>
      <!--
    <div
      class="md-layout-item md-alignment-top-left md-medium-size-20 md-small-size-20 md-xsmall-size-100"
    >
      <md-field>
        <label>Nick name</label>
        <md-input disabled="disabled" v-model="user.nickName" />
      </md-field>
    </div>
    -->
      <!--
  <div
    class="md-layout-item md-alignment-top-left md-medium-size-30 md-small-size-30 md-xsmall-size-100"
  >
    <md-datepicker disabled="disabled" v-model="user.dateOfBirth">
      <label>Date of birth</label>
    </md-datepicker>
    </div>
    -->
    </md-card-content>
    <md-card-actions md-alignment="right">
      <md-button @click="onSave()" v-if="user.id == null">Save</md-button>
      <md-button @click="onSave()" v-else>Update</md-button>
    </md-card-actions>
  </md-card>
</template>
<script>
export default {
  name: "user-edit",
  props: ["user", "onSave"]
};
</script>
