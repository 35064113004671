<template>
  <div>
    <md-table v-model="invitations" md-sort="name">
      <md-table-row slot="md-table-row" slot-scope="{ item, index }">
        <md-table-cell md-label="Invitation hash"
          >{{ item.invitationHash }}
        </md-table-cell>
        <md-table-cell md-label="Expires" width="100%">
          <show-timestamp :date="item.expiresAt" />
        </md-table-cell>
        <md-table-cell>
          <md-button
            class="md-icon-button"
            v-on:click="$emit('onDelete', item, index)"
          >
            <delete-icon />
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
export default {
  name: "group-invitation-hash-list",
  props: ["invitations"]
};
</script>
