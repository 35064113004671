<template>
  <md-card-content class="md-primary">
    <div class="md-layout md-gutter">
      <div
        class="md-layout-item md-alignment-top-left md-medium-size-33 md-small-size-100 md-xsmall-size-100"
      >
        <md-field>
          <label>Organisation name</label>
          <md-input v-model="edit.name" />
        </md-field>
      </div>

      <div
        class="md-layout-item md-alignment-top-left md-medium-size-33 md-small-size-50 md-xsmall-size-50"
      >
        <StructureTypeChoice
          structure-key="organisation"
          :value="edit.type"
          @onStructureTypeChange="item => $emit('onStructureTypeChange', item)"
        />
        <languages-choice
          :language="edit.language"
          @onLanguagesChanged="item => $emit('onLanguagesChanged', item)"
        />
      </div>

      <div
        class="md-layout-item md-alignment-top-left md-medium-size-33 md-small-size-50 md-xsmall-size-50"
      >
        <ChooseTimeZone
          :timezone="edit.timezone"
          @onTimezoneChange="item => $emit('onTimezoneChange', item)"
        />
        <ChooseCountry
          :country="edit.country"
          @onCountryChange="item => $emit('onCountryChange', item)"
        />
      </div>
      <div
        class="md-layout-item md-alignment-top-left md-medium-size-33 md-small-size-50 md-xsmall-size-100"
      >
        <md-datepicker v-model="edit.trialUntil">
          <label>Trial end date</label>
        </md-datepicker>
        <md-datepicker v-model="edit.paidUntil">
          <label>Paid until date</label>
        </md-datepicker>
      </div>
    </div>
  </md-card-content>
</template>
<script>
import ChooseTimeZone from "../Layout/TimeZoneChooseContainer";
import LanguagesChoice from "@/components/Layout/LanguagesChoice";
import ChooseCountry from "@/components/Layout/CountryChoice";
import StructureTypeChoice from "@/components/Layout/StructureTypeChoice";

export default {
  name: "organisation-edit",
  components: {
    StructureTypeChoice,
    ChooseCountry,
    ChooseTimeZone,
    LanguagesChoice
  },
  props: ["edit"]
};
</script>
