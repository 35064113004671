<template>
  <md-icon class="profile-edit-icon"
    >{{ $icons.user_profile_edit
    }}<md-tooltip>{{ toolTip }}</md-tooltip></md-icon
  >
</template>
<script>
export default {
  name: "user_profile_edit-icon",
  props: {
    toolTip: {
      type: String,
      default: "Profile edit"
    },
    profileId: {
      type: Number,
      default: null
    }
  }
};
</script>
