<template>
  <md-button
    v-if="organisation != null && organisation.id != null"
    :to="{
      name: 'Edit Organisation',
      params: { organisation_id: organisation.id }
    }"
    >{{ organisation.name }}</md-button
  >
</template>
<script>
export default { name: "organisation-button", props: ["organisation"] };
</script>
