import Api from "./api/RestAPI";
import { PaginatedStreamDataIterator } from "@/services/api/ResultDataIterator";

export default {
  getUserListIterator() {
    return new PaginatedStreamDataIterator("/api/v1/users");
  },
  getListInGroupIterator(groupId) {
    return new PaginatedStreamDataIterator(
      "/api/group/v1/" + groupId + "/users"
    );
  },
  getListInOrganisationIterator(organisationId) {
    return new PaginatedStreamDataIterator(
      "/api/organisation/v1/" + organisationId + "/users"
    );
  },
  get(user_id) {
    return Api.get("/api/user/v1/" + user_id);
  },
  delete(user_id) {
    return Api.delete("/api/user/v1/" + user_id);
  },
  disable(user_id) {
    return Api.put("/api/user/v1/" + user_id + "/disable");
  },
  addAccountToUser(user_id, type, value) {
    return Api.post("/api/user/v1/" + user_id + "/account", {
      value: value,
      type: type
    });
  },
  createUserAndAddAccount(type, value) {
    return Api.post("/api/user/v1/account", { value: value, type: type });
  },
  createUserAndAddAccountIntoGroup(group_id, type, value) {
    return Api.post("/api/user/v1/group/" + group_id + "/account", {
      value: value,
      type: type
    });
  },
  createUserAndAddAccountIntoOrganisation(organisation_id, type, value) {
    return Api.post(
      "/api/user/v1/organisation/" + organisation_id + "/account",
      { value: value, type: type }
    );
  },
  setUserLanguage(user_id, language) {
    return Api.post(`/api/user/v1/language/${user_id}/${language}`);
  },
  getMyLanguage() {
    return Api.get(`/api/user/v1/language`);
  },
  setMyLanguage(language) {
    return Api.post(`/api/user/v1/language/${language}`);
  },

  createUser: async function(options) {
    const argsToUser = {
      ...{
        userId: null,
        type: null,
        value: null,
        organisationId: null,
        groupId: null
      },
      ...options
    };
    const result = { ...{}, ...argsToUser };
    if (argsToUser.userId) {
      const requestResult = await this.addAccountToUser(
        argsToUser.userId,
        argsToUser.type,
        argsToUser.value
      );
      return { ...result, ...{ userId: requestResult.result.id } };
    } else if (argsToUser.groupId) {
      const requestResult = await this.createUserAndAddAccountIntoGroup(
        argsToUser.groupId,
        argsToUser.type,
        argsToUser.value
      );
      return { ...result, ...{ userId: requestResult.result.id } };
    } else if (argsToUser.organisationId) {
      const requestResult = await this.createUserAndAddAccountIntoOrganisation(
        argsToUser.organisationId,
        argsToUser.type,
        argsToUser.value
      );
      return { ...result, ...{ userId: requestResult.result.id } };
    } else {
      const requestResult = await UserService.createUserAndAddAccount(
        argsToUser.type,
        argsToUser.value
      );
      return { ...result, ...{ userId: requestResult.result.id } };
    }
  }
};
