<template>
  <div class="md-layout md-gutter">
    <div
      class="md-layout-item md-alignment-top-left md-xlarge-size-100 md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
    >
      <cb-image-upload @onImageChange="onImageChange" :image-url="imageUrl" />
    </div>
    <div
      class="md-layout-item md-alignment-top-left md-xlarge-size-33 md-large-size-30 md-medium-size-30 md-small-size-50 md-xsmall-size-100"
    >
      <md-field>
        <label>Title</label>
        <md-input v-model="profile.title" placeholder="Mr, Mrs, Ms ..." />
      </md-field>
    </div>
    <div
      class="md-layout-item md-alignment-top-left md-xlarge-size-33 md-large-size-70 md-medium-size-70 md-small-size-100 md-xsmall-size-100 md-layout md-gutter"
    >
      <div
        class="md-layout-item md-alignment-top-left md-xlarge-size-50 md-large-size-50 md-medium-size-50 md-small-size-50 md-xsmall-size-100"
      >
        <md-field>
          <label>First name</label>
          <md-input v-model="profile.firstName" />
        </md-field>
      </div>
      <div
        class="md-layout-item md-alignment-top-left md-xlarge-size-50 md-large-size-50 md-medium-size-50 md-small-size-50 md-xsmall-size-100"
      >
        <md-field>
          <label>Last name</label>
          <md-input v-model="profile.lastName" />
        </md-field>
      </div>
    </div>
    <div
      class="md-layout-item md-alignment-top-left md-xlarge-size-33 md-large-size-30 md-medium-size-30 md-small-size-50 md-xsmall-size-100"
    >
      <md-radio v-model="profile.genderChoice" value="male">Male</md-radio>
      <md-radio v-model="profile.genderChoice" value="female">Female</md-radio>
      <md-radio v-model="profile.genderChoice" value="other">Other</md-radio>
      <md-field v-if="profile.genderChoice === 'other'">
        <label>Enter your gender</label>
        <md-input v-model="profile.gender" />
      </md-field>
    </div>
    <div
      class="md-layout-item md-alignment-top-left md-xlarge-size-33 md-large-size-30 md-medium-size-30 md-small-size-50 md-xsmall-size-100"
    >
      <md-datepicker v-model="profile.dateOfBirth">
        <label>Select date of birth</label>
      </md-datepicker>
    </div>

    <div
      class="md-layout-item md-alignment-top-left md-xlarge-size-33 md-large-size-30 md-medium-size-30 md-small-size-50 md-xsmall-size-100"
      v-if="language.loaded && !language.edit"
    >
      <md-field>
        <md-button class="md-icon-button" v-on:click="language.edit = true">
          <md-icon>edit</md-icon>
        </md-button>
        <label>Language</label>
        <md-input v-model="language.value.label" :disabled="true" />
      </md-field>
    </div>
    <div
      class="md-layout-item md-alignment-top-left md-xlarge-size-33 md-large-size-30 md-medium-size-30 md-small-size-50 md-xsmall-size-100"
      v-if="language.loaded && language.edit"
    >
      <languages-choice
        :language="language.value"
        @onLanguagesChanged="item => (language.changed = item)"
      />
      <md-button class="md-icon-button" v-on:click="onLanguageCancel()">
        <md-icon>cancel</md-icon>
      </md-button>
      <md-button
        class="md-icon-button"
        v-if="language.value !== language.changed"
        v-on:click="changeLanguage()"
      >
        <md-icon>{{ $icons.done }}</md-icon>
      </md-button>
    </div>
  </div>
</template>
<script>
import UserService from "@/services/UserService";
import LanguagesChoice from "@/components/Layout/LanguagesChoice";

export default {
  name: "profile-edit",
  props: ["profile", "imageUrl"],
  components: {
    LanguagesChoice
  },
  data: () => ({
    language: {
      edit: false,
      loaded: false,
      value: null,
      changed: null
    }
  }),
  methods: {
    onLanguageCancel: function() {
      this.language.changed = this.language.value;
      this.language.edit = false;
    },
    changeLanguage: function() {
      this.language.value = this.language.changed;
      this.language.edit = false;
      this.$emit("onLanguagesChanged", this.language.value);
    },
    onImageChange: function(image) {
      this.$emit("onImageChange", image);
    }
  },
  created: async function() {
    this.language.loaded = false;
    const result = await UserService.getMyLanguage();
    this.language.value = result.result;
    this.language.changed = result.result;
    this.language.loaded = true;
  }
};
</script>
