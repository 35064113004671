import Api from "@/services/api/RestAPI";

export class BaseResultDataIterator {
  pendingQueue = [];

  addToQueue(keyword) {
    this.pendingQueue.push(keyword);
  }

  getQueueIndex(keyword) {
    return this.pendingQueue.indexOf(keyword);
  }

  removeFromQueue(keyword) {
    const index = this.getQueueIndex(keyword);
    if (index < 0) return false;
    this.pendingQueue.splice(index, 1);
    return true;
  }

  async doWithoutWaitOnQueue(keyword, callback) {
    return new Promise((resolve, reject) => {
      this.addToQueue(keyword);
      callback()
        .then(resolve)
        .catch(reject)
        .finally(() => this.removeFromQueue(keyword));
    });
  }

  async awaitOnQueueComplete(callback) {
    return new Promise((resolve, reject) => {
      awaitPending(
        () => this.pendingQueue.length > 0,
        () =>
          this.doWithoutWaitOnQueue("await", callback)
            .then(resolve)
            .catch(reject)
      );
    });
  }
}

const awaitPending = (pending, callback) => {
  if (pending()) {
    setTimeout(() => {
      awaitPending(pending, callback);
    }, 1);
  } else {
    callback();
  }
};

export class PaginatedStreamDataIterator extends BaseResultDataIterator {
  apiPath;
  nextHash;
  previousHashHistory = [];
  result;
  queryParameters = {};
  currentPageHash = null;

  constructor(apiPath) {
    super();
    this.apiPath = apiPath;
  }

  processResult(result) {
    if (result && result.pageInfo) {
      if (result.result && result.result.length > 0) {
        this.nextHash = result.pageInfo.nextHash;
      }
      this.currentPageHash = result.pageInfo.previousHash;
    } else {
      this.nextHash = undefined;
    }
    this.result = result.result;
  }

  async initAPI() {
    this.nextHash = undefined;
    this.previousHashHistory = [];
    const result = await Api.get(this.apiPath, this.queryParameters);
    this.processResult(result);
  }

  async reset() {
    return this.awaitOnQueueComplete(() => this.initAPI());
  }

  async setQueryParameter(key, value) {
    return this.awaitOnQueueComplete(async () => {
      const parameterValue =
        value !== null && value !== undefined ? value.trim() : undefined;
      if (parameterValue === undefined && !this.queryParameters[key]) {
        return;
      }
      if (parameterValue === this.queryParameters[key]) {
        return;
      }
      if (parameterValue) {
        this.queryParameters[key] = value;
      } else {
        delete this.queryParameters[key];
      }
      await this.initAPI();
    });
  }

  fetchNext() {
    if (!this.nextHash) {
      return;
    }
    // we do not care about result so disabling promise call check
    // noinspection JSIgnoredPromiseFromCall
    this.doWithoutWaitOnQueue("fetch", async () => {
      const result = await Api.get(this.apiPath, {
        ...this.queryParameters,
        ...{ listHash: this.nextHash }
      });
      this.processResult(result);
    });
  }

  async getPrevious() {
    if (!this.nextHash) {
      return;
    }
    // we do not care about result so disabling promise call check
    // noinspection JSIgnoredPromiseFromCall
    return this.doWithoutWaitOnQueue("fetch", async () => {
      const previousHash = this.previousHashHistory.pop();
      if (!previousHash) {
        return [];
      }
      const previousResult = await Api.get(this.apiPath, {
        ...this.queryParameters,
        ...{ listHash: previousHash }
      });
      this.processResult(previousResult);
      const result = this.result !== undefined ? [...this.result] : [];
      this.fetchNext();
      return result;
    });
  }

  async getNext() {
    return this.awaitOnQueueComplete(async () => {
      if (this.result === undefined) {
        await this.initAPI();
      }
      const result = this.result !== undefined ? [...this.result] : [];
      this.previousHashHistory.push(this.currentPageHash);
      this.fetchNext();
      return result;
    });
  }

  async hasNext() {
    return this.awaitOnQueueComplete(async () => {
      if (this.result === undefined) {
        await this.initAPI();
      }
      return this.result !== undefined && this.result.length > 0;
    });
  }

  async hasPrevious() {
    return this.awaitOnQueueComplete(async () => {
      if (this.result === undefined) {
        await this.initAPI();
      }
      return this.previousHashHistory.length > 1;
    });
  }
}
