<template>
  <md-icon class="delete-icon"
    >{{ $icons.delete }}<md-tooltip>{{ toolTip }}</md-tooltip></md-icon
  >
</template>
<script>
export default {
  name: "delete-icon",
  props: {
    toolTip: {
      type: String,
      default: "Delete"
    }
  }
};
</script>
