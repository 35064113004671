import Api from "@/services/api/RestAPI";

const createTimedCache = function(path, resolver = undefined) {
  const values = {
    updated: null,
    values: null
  };
  return async function() {
    const time = new Date().getTime();
    if (
      values.values == null ||
      values.updated == null ||
      time - values.updated > 60000
    ) {
      const result = await Api.get(path);
      if (resolver === undefined) {
        values.values = result.result;
      } else {
        values.values = resolver(result.result);
      }
      values.updated = time;
    }
    return values.values;
  };
};

const translateStructure = (() => {
  const STRUCTURE_TYPE_TRANSLATIONS = {
    CLASS: "Class",
    AUTO: "Auto onboarded",
    EDU: "School",
    LTD: "Company",
    SUPPORT: "Support",
    EPIC: "Education Pioneers & Influencers Community"
  };
  return value => {
    return {
      key: value,
      label: STRUCTURE_TYPE_TRANSLATIONS[value] || value
    };
  };
})();

export default {
  listTimezones: createTimedCache("/api/constants/timezones/v1"),
  listCounties: createTimedCache("/api/locale/countries/v1"),
  listLanguages: createTimedCache("/api/constants/languages/v1"),
  listStructureTypes: createTimedCache(
    "/api/constants/structure/types/v1",
    result => {
      return {
        group: result.group.map(translateStructure),
        organisation: result.organisation.map(translateStructure)
      };
    }
  )
};
