import Organisations from "@/pages/Organisation/OrganisationListPage.vue";
import Authorize from "@/pages/Authorize.vue";
import Organisation from "@/pages/Organisation/OrganisationEditPage.vue";
import Groups from "@/pages/Group/GroupListPage.vue";
import Group from "@/pages/Group/GroupEditPage.vue";

import UserEditPage from "@/pages/User/UserEditPage.vue";
import UserEditInGroupPage from "@/pages/User/UserEditInGroupPage.vue";
import UserEditInOrganisationPage from "@/pages/User/UserEditInOrganisationPage";
import UserListPage from "@/pages/User/UserListPage.vue";
import UserListInGroupPage from "@/pages/User/UserListInGroupPage.vue";
import UserListInOrganisationPage from "@/pages/User/UserListInOrganisationPage";
import MyProfile from "@/pages/UserProfile/MyProfile";

import InterestsBucketListPage from "@/pages/InterestsBucketList.vue";
import SecurityManagement from "@/pages/Security/SecurityManagement";
import AutoQuestions from "@/pages/AutoQuestions";

const routes = [
  {
    path: "/",
    component: Organisations
  },
  {
    path: "/authorize",
    name: "Authorize",
    component: Authorize
  },
  {
    path: "/dashboard",
    name: "Home",
    component: Organisations
  },
  {
    path: "/organisations",
    name: "Organisations",
    component: Organisations
  },
  {
    path: "/organisation/:organisation_id",
    name: "Edit Organisation",
    component: Organisation,
    props: true
  },
  {
    path: "/organisation",
    name: "New Organisation",
    component: Organisation
  },
  {
    path: "/organisation/:organisation_id/groups",
    name: "List Groups",
    component: Groups,
    props: true
  },
  {
    path: "/organisation/:organisation_id/group",
    name: "Add Group",
    component: Group,
    props: true
  },
  {
    path: "/organisation/:organisation_id/group/:group_id",
    name: "Edit Group",
    component: Group,
    props: true
  },
  {
    path: "/organisation/:organisation_id/users",
    name: "List organisation users",
    component: UserListInOrganisationPage,
    props: true
  },
  {
    path: "/group/:group_id/users",
    name: "List group users",
    component: UserListInGroupPage,
    props: true
  },
  {
    path: "/users",
    name: "List all users",
    component: UserListPage
  },
  {
    path: "/organisation/:organisation_id/user/:user_id",
    name: "Edit Organisation User",
    component: UserEditInOrganisationPage,
    props: true
  },
  {
    path: "/organisation/:organisation_id/user",
    name: "New Organisation User",
    component: UserEditInOrganisationPage,
    props: true
  },
  {
    path: "/group/:group_id/user/:user_id",
    name: "Edit Group User",
    component: UserEditInGroupPage,
    props: true
  },
  {
    path: "/group/:group_id/user",
    name: "New Group User",
    component: UserEditInGroupPage,
    props: true
  },
  {
    path: "/user",
    name: "Add User",
    component: UserEditPage,
    props: true
  },
  {
    path: "/user/:user_id",
    name: "Edit User",
    component: UserEditPage,
    props: true
  },
  {
    path: "/profile",
    name: "Edit my profile",
    component: MyProfile,
    props: true
  },
  {
    path: "/interests_bucket_list",
    name: "Interests & Bucket list",
    component: InterestsBucketListPage,
    props: true
  },
  {
    path: "/security",
    name: "Security management",
    component: SecurityManagement,
    props: true
  },
  {
    path: "/auto-questions",
    name: "Auto questions",
    component: AutoQuestions,
    props: true
  }
];

export default routes;
