<template>
  <md-empty-state
    :md-icon="icon"
    :md-label="label"
    :md-description="description"
  >
    <md-button
      class="md-primary md-raised"
      v-if="button"
      v-on:click="$emit('addItem')"
      >{{ button }}
    </md-button>
  </md-empty-state>
</template>
<script>
export default {
  props: ["icon", "label", "description", "button"],
  name: "cb-empty-state"
};
</script>
