<template>
  <md-button
    v-if="
      group != null &&
        group.id != null &&
        organisation != null &&
        organisation.id != null
    "
    :to="{
      name: 'Edit Group',
      params: { group_id: group.id, organisation_id: organisation.id }
    }"
    >{{ group.name }}</md-button
  >
</template>
<script>
export default { name: "group-button", props: ["group", "organisation"] };
</script>
