import Api from "./api/RestAPI";

export default {
  list() {
    return Api.get("/api/auto-questions/v1");
  },
  create(data) {
    return Api.post("/api/auto-questions/v1", data);
  },
  update(id, data) {
    return Api.put("/api/auto-questions/v1/" + id, data);
  },
  delete(id) {
    return Api.delete("/api/auto-questions/v1/" + id);
  }
};
