<template>
  <user-edit-component
    :user_id="user_id"
    :group="group"
    :organisation="organisation"
  />
</template>
<script>
import UserEditComponent from "./Shared/UserEditComponent";
import GroupService from "@/services/GroupService";
import OrganisationService from "@/services/OrganisationService";

export default {
  name: "user-edit-page",
  props: ["user_id", "group_id"],
  components: {
    UserEditComponent
  },
  data() {
    return {
      group: {},
      organisation: {}
    };
  },
  created: function() {
    GroupService.get(this.group_id)
      .then(result => {
        this.group = result.result;
        OrganisationService.get(this.group.organisationId)
          .then(result => {
            this.organisation = result.result;
          })
          .catch(error => {
            this.resultError = error;
          });
      })
      .catch(error => {
        this.resultError = error;
      });
  }
};
</script>
