<template>
  <md-card v-if="token !== null && token !== undefined">
    <md-card-header>
      <md-card-header-text>
        <div class="md-title">
          Generated Bearer Api token
        </div>
      </md-card-header-text>
    </md-card-header>
    <md-card-content>
      <md-field>
        <md-textarea
          v-model="token"
          readonly
          class="md-layout-item md-size-100 md-dynamic-height"
          md-autogrow
        />
      </md-field>
    </md-card-content>
    <md-card-actions md-alignment="right">
      <md-button @click="onTokenClear">Hide</md-button>
    </md-card-actions>
  </md-card>
</template>

<script>
export default {
  name: "api-key-show",
  props: ["token", "onTokenClear"]
};
</script>
