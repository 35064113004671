<template>
  <span v-if="value" class="md-layout-item md-alignment-top-right">
    {{ value }}
    <md-tooltip md-direction="top">{{ tooltip }}</md-tooltip>
  </span>
</template>
<script>
const resolveDate = (value, prefix) => {
  if (value === null || value === undefined || value.length === 0) return null;
  const dateParts = value.split("-");
  if (dateParts.length !== 3) return null;
  return [
    new Date(dateParts[0], dateParts[1], dateParts[2]).getUTCMilliseconds(),
    `${prefix} until ${value}`
  ];
};
export default {
  name: "paid-and-trial-in-list",
  props: ["item"],
  data: function() {
    return {
      value: null,
      tooltip: null
    };
  },
  created: function() {
    this.value = null;
    if (!this.item) {
      return;
    }
    const values = [
      resolveDate(this.item.paidUntil, "Paid"),
      resolveDate(this.item.trialUntil, "Trial")
    ]
      .filter(e => e !== null)
      .sort((value1, value2) => value1[0] - value2[0])
      .map(e => e[1]);
    if (values.length > 0) {
      this.value = values[0];
      this.tooltip = values.join(", ");
    }
  }
};
</script>
