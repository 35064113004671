<template>
  <md-icon class="organisation-users-icon"
    >{{ $icons.organisationUsers
    }}<md-tooltip>{{ toolTip }}</md-tooltip></md-icon
  >
</template>
<script>
export default {
  name: "organisation-users-icon",
  props: {
    toolTip: {
      type: String,
      default: "organisation users"
    }
  }
};
</script>
