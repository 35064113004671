<template>
  <hide-show-card title="Actions" id="user_actions">
    <md-dialog-confirm
      :md-active.sync="confirmDeleteUser"
      md-title="Are you sure you want to delete the user?"
      md-content="This process is irreversible. Once you remove the user all its data will be gone!!"
      md-confirm-text="OK"
      md-cancel-text="Cancel"
      @md-confirm="onDelete"
    />
    <md-dialog-confirm
      :md-active.sync="confirmDisableUser"
      md-title="Are you sure you want to disable the user?"
      md-content="When you disable the user he can not log in and will be hidden!"
      md-confirm-text="OK"
      md-cancel-text="Cancel"
      @md-confirm="onDisable"
    />
    <md-dialog-confirm
      :md-active.sync="confirmEnableUser"
      md-title="Are you sure you want to enable the user?"
      md-content="User is currently hidden and disabled, are you sure you want to enable him?"
      md-confirm-text="OK"
      md-cancel-text="Cancel"
      @md-confirm="onDisable"
    />
    <md-card-content class="md-gutter" v-if="user.canChange">
      <span
        >User actions are disabled because user is being processed to delete
        since {{ user.deletedAt | moment("DD.MM.YYYY HH:mm:ss") }}</span
      >
    </md-card-content>
    <md-card-content class="md-gutter" v-else>
      <div class="md-layout md-gutter">
        <div class="md-layout-item">
          <md-switch v-model="deleted">Deleted</md-switch>
        </div>
        <div class="md-layout-item">
          <md-switch v-model="disabled" v-if="user.disabledAt"
            >Disabled
            {{ user.disabledAt | moment("DD.MM.YYYY HH:mm:ss") }}</md-switch
          >
          <md-switch v-model="disabled" v-else>Disabled</md-switch>
        </div>
      </div>
    </md-card-content>
  </hide-show-card>
</template>
<script>
import HideShowCard from "@/components/Layout/HideShowCard";
export default {
  name: "user-edit",
  props: ["user", "onDelete", "onDisable"],
  data() {
    return {
      confirm: { delete: false, disable: false, enable: false }
    };
  },
  components: {
    HideShowCard
  },
  computed: {
    deleted: {
      get: function() {
        return !!this.user.deletedAt;
      },
      set: function() {
        this.confirm.delete = true;
      }
    },
    disabled: {
      get: function() {
        return !!this.user.disabledAt;
      },
      set: function() {
        this.confirm.disable = true;
      }
    },
    confirmDeleteUser: {
      get: function() {
        return this.confirm.delete;
      },
      set: function() {
        this.confirm.delete = false;
      }
    },
    confirmDisableUser: {
      get: function() {
        return this.confirm.disable;
      },
      set: function() {
        this.confirm.disable = false;
      }
    },
    confirmEnableUser: {
      get: function() {
        return this.confirm.enable;
      },
      set: function() {
        this.confirm.enable = false;
      }
    }
  }
};
</script>
