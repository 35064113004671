import jQuery from "jquery";

function failWithPromise(status, message) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject({ status: status, message: message });
    }, 1);
  });
}

function doRequest(options) {
  if (options == null) {
    return failWithPromise(-1, "options should not be null!");
  }
  if (options.path == null) {
    return failWithPromise(-1, "path should not be null!");
  }
  if (!options.path.startsWith("/")) {
    options.path = "/" + options.path;
  }
  var _options = {
    url: process.env.VUE_APP_BACKEND_API_URL + options.path,
    method: options.method
  };
  if (options.headers != null) {
    _options.headers = options.headers;
  }

  if (options.data) {
    if (options.method === "GET" || options.method === "DELETE") {
      _options.data = options.data;
    } else {
      _options.dataType = "json";
      _options.contentType = "application/json";
      _options.data = JSON.stringify(options.data);
    }
  }

  return new Promise((resolve, reject) => {
    jQuery
      .ajax(_options)
      .done(response => {
        resolve(response);
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        /*
        console.error(textStatus);
        console.error(errorThrown);
        console.error(jqXHR);
         */
        if (jqXHR.responseText != null) {
          try {
            if (jqXHR.responseText !== "") {
              var jsonResult = JSON.parse(jqXHR.responseText);
              reject({ status: jqXHR.status, error: jsonResult });
            } else {
              reject({ status: jqXHR.status, error: null });
            }
            return;
          } catch (e) {
            console.error(e);
          }
        }
        reject({ status: jqXHR.status });
      });
  });
}

export default {
  doRequest: options => doRequest(options)
};
