var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.group != null &&
      _vm.group.id != null &&
      _vm.organisation != null &&
      _vm.organisation.id != null
  )?_c('md-button',{attrs:{"to":{
    name: 'Edit Group',
    params: { group_id: _vm.group.id, organisation_id: _vm.organisation.id }
  }}},[_vm._v(_vm._s(_vm.group.name))]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }