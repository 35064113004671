<template>
  <md-list class="md-inset checkin-question-item">
    <md-list-item
      v-for="weekday in weekDays"
      :key="weekday.dayOfWeek"
      class="md-layout md-gutter"
    >
      <span class="md-layout-item">
        {{ weekday.dayOfWeek }}
      </span>
      <md-field class="md-layout-item">
        <md-input type="time" v-model="weekday.when" required />
      </md-field>
      <md-button
        :disabled="!weekday.when"
        class="md-icon-button"
        @click="changeItem(weekday)"
      >
        <done-icon tool-tip="Add new check in group" />
      </md-button>
      <enable-disable-button
        tool-tip-disabled="Enable time"
        tool-tip-enabled="Disable time"
        :disabled="!weekday.id"
        :value="weekday.enabled"
        @click="enableItem(weekday.id, !weekday.enabled)"
      />
      <delete-button
        :disabled="!weekday.id"
        tool-tip="Remove time"
        @click="removeItem(weekday.id)"
      />
    </md-list-item>
  </md-list>
</template>

<script>
export default {
  name: "check-in-schedulers",
  props: ["schedulers"],
  data() {
    return {
      weekDays: []
    };
  },
  methods: {
    enableItem: function(id, enabled) {
      this.$emit("onCheckInSchedulerEnabled", { id: id, enabled: enabled });
    },
    removeItem: function(id) {
      this.$emit("onCheckInSchedulerRemoved", id);
    },
    changeItem: function(item) {
      this.$emit("onCheckInSchedulerAdded", item);
    }
  },
  watch: {
    $route: "schedulers",
    $props: {
      handler() {
        const weekDays = [];
        [
          "MONDAY",
          "TUESDAY",
          "WEDNESDAY",
          "THURSDAY",
          "FRIDAY",
          "SATURDAY",
          "SUNDAY"
        ].forEach(item => {
          const weekDay = {
            id: null,
            dayOfWeek: item,
            when: null,
            enabled: false
          };
          if (this.schedulers) {
            this.schedulers
              .filter(e => e.dayOfWeek === item)
              .forEach(d => {
                weekDay.id = d.id;
                weekDay.when = d.when;
                weekDay.enabled = d.enabled;
              });
          }
          weekDays.push(weekDay);
        });
        this.weekDays = weekDays;
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
