import Vue from "vue";
import ButtonGroup from "./ButtonGroup";
import ButtonGroupList from "./ButtonGroupList";
import ButtonOrganisation from "./ButtonOrganisation";
import ButtonGroupUserList from "./ButtonGroupUserList";
import ButtonOrganisationUserList from "./ButtonOrganisationUserList";
import DeleteButton from "./DeleteButton";
import EnableDisableButton from "./EnableDisableButton";

Vue.component(ButtonGroup.name, ButtonGroup);
Vue.component(ButtonGroupList.name, ButtonGroupList);
Vue.component(ButtonOrganisation.name, ButtonOrganisation);
Vue.component(ButtonGroupUserList.name, ButtonGroupUserList);
Vue.component(ButtonOrganisationUserList.name, ButtonOrganisationUserList);
Vue.component(DeleteButton.name, DeleteButton);
Vue.component(EnableDisableButton.name, EnableDisableButton);
