<template>
  <main-app-container
    :title="titleName"
    add_text="New User"
    :icon="$icons.users"
    :organisation="organisation"
    :group="group"
    @addItem="$emit('addItem')"
    :searchLabel="!users || users.length === 0 ? null : 'Search users'"
    @onSearchChange="onSearchChange"
  >
    <cb-empty-state
      v-if="users.length === 0 && !loading && !searchState"
      :icon="$icons.users"
      label="Create new user"
      description="Users are the ones who make thethis.searchState experience full!"
      button="Create new user"
      @addItem="$emit('addItem')"
    />
    <cb-empty-state
      v-if="users.length === 0 && !loading && searchState"
      :icon="$icons.users"
      label="Nothing found"
      description="We search from name, e-mails and phone numbers- but could not find what you are looking for!"
    />
    <form-errors :resultError="resultError" />
    <md-card v-if="users.length > 0">
      <md-card-header
        v-if="
          (organisation != null && organisation.id != null) ||
            (group != null && group.id != null)
        "
      >
        <md-card-header-text>
          <div class="md-title"></div>
        </md-card-header-text>
        <md-menu md-size="big" md-direction="bottom-end">
          <md-button class="md-icon-button" md-menu-trigger>
            <menu-dropdown-icon />
          </md-button>

          <md-menu-content>
            <organisation-menu-item :organisation="organisation" />
            <organisation-user-list-menu-item :organisation="organisation" />
            <group-list-menu-item :organisation="organisation" />
            <group-menu-item :organisation="organisation" :group="group" />
            <group-user-list-menu-item :group="group" />
          </md-menu-content>
        </md-menu>
      </md-card-header>

      <md-card-content>
        <user-list
          :users="users"
          :loading="loading"
          :group="group"
          :organisation="organisation"
        />
      </md-card-content>
      <md-card-actions>
        <md-button
          :disabled="!hasPreviousPage"
          v-on:click="$emit('onPreviousClick')"
          >Previous</md-button
        >
        <md-button :disabled="!hasNextPage" v-on:click="$emit('onNextClick')"
          >Next</md-button
        >
      </md-card-actions>
    </md-card>
  </main-app-container>
</template>
<script>
import { UserList } from "@/components/User/";

export default {
  name: "user-list-component",
  props: [
    "users",
    "resultError",
    "group",
    "organisation",
    "loading",
    "hasNextPage",
    "hasPreviousPage"
  ],
  components: {
    UserList
  },
  data() {
    return {
      searchState: false
    };
  },
  methods: {
    onSearchChange(val) {
      this.searchState =
        val !== null && val !== undefined && val.trim().length > 0;
      this.$emit("onUserSearch", val);
    }
  },
  computed: {
    titleName: function() {
      if (
        (!this.users || this.users.length === 0) &&
        this.group &&
        this.group.name
      ) {
        return `Add user in group "${this.group.name}"`;
      }
      if (
        (!this.users || this.users.length === 0) &&
        this.organisation &&
        this.organisation.name
      ) {
        return `Add user in organisation "${this.organisation.name}"`;
      }
      if (!this.users || this.users.length === 0) {
        return `Create new user`;
      }

      if (this.group && this.group.name) {
        return `"${this.group.name}" users`;
      }
      if (this.organisation && this.organisation.name) {
        return `"${this.organisation.name}" users`;
      }
      return "All users";
    }
  }
};
</script>
