<template>
  <md-menu-item
    v-if="organisation != null && organisation.id != null"
    :to="{
      name: 'Edit Organisation',
      params: { organisation_id: organisation.id }
    }"
  >
    <span>{{ organisation.name }}</span>
    <organisation-icon :tool-tip="organisation.name" :organisation-id="organisation.id"/>
  </md-menu-item>
</template>
<script>
export default { name: "organisation-menu-item", props: ["organisation"] };
</script>
