var firebaseUiMock = {
  start: (elementId, config) => {
    var container = document.getElementById(elementId.substring(1));

    var rootElement = document.createElement("div");
    rootElement.className =
      "firebaseui-container firebaseui-page-provider-sign-in firebaseui-id-page-provider-sign-in firebaseui-use-spinner";
    container.appendChild(rootElement);

    var firebaseCardContent = document.createElement("div");
    firebaseCardContent.className = "firebaseui-card-content";
    rootElement.appendChild(firebaseCardContent);

    var firebaseUlList = document.createElement("ul");
    firebaseUlList.className = "firebaseui-idp-list";
    firebaseCardContent.appendChild(firebaseUlList);

    function addListItem(text) {
      var liItem = document.createElement("li");
      liItem.className = "firebaseui-list-item";
      firebaseUlList.appendChild(liItem);

      var liButton = document.createElement("span");
      liButton.className =
        "firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-idp-google firebaseui-id-idp-button";
      if (
        text.indexOf(":mail") > -1 &&
        text.indexOf(":user") > -1 &&
        text.indexOf(":phone") > -1
      ) {
        liButton.style.backgroundColor = "#00FF00";
      } else if (text.indexOf(":mail") > -1 && text.indexOf(":user") > -1) {
        liButton.style.backgroundColor = "#99FF99";
      } else if (text.indexOf(":phone") > -1 && text.indexOf(":user") > -1) {
        liButton.style.backgroundColor = "#a0FFa0";
      } else if (text.indexOf(":mail") > -1) {
        liButton.style.backgroundColor = "#ffFFa1";
      } else if (text.indexOf("invalid") > -1) {
        liButton.style.backgroundColor = "#ff0000";
        liButton.style.color = "#ffffff";
      }

      liButton.innerText = text;
      liItem.appendChild(liButton);
    }

    addListItem("invalid login");
    addListItem("mock");
    addListItem("mock:user:mail_superuser");
    addListItem("mock:user:mail_admin");
    addListItem("mock:user:mail_user");

    for (var x = 0; x < Math.pow(2, config.signInOptions.length); x++) {
      var tmp_result = [];
      var i = config.signInOptions.length - 1;
      do {
        if ((x & (1 << i)) !== 0) {
          tmp_result.push(config.signInOptions[i]);
        }
      } while (i--);

      if (tmp_result.length >= 1) {
        addListItem("mock:" + tmp_result.join(":"));
      }
    }

    var firebaseCardFooter = document.createElement("div");
    firebaseCardFooter.className =
      "firebaseui-card-footer firebaseui-provider-sign-in-footer";
    container.appendChild(firebaseCardFooter);

    firebaseUlList.addEventListener("click", function(event) {
      var elementTxt = event.target.innerText.toLowerCase();
      if (elementTxt.startsWith("mock")) {
        loginStatus.user = {
          getIdTokenResult: () =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                if (elementTxt === "invalid login") {
                  reject(elementTxt);
                } else {
                  resolve({ token: elementTxt });
                }
              }, 1);
            })
        };
      } else {
        loginStatus.user = null;
      }
      callListeners();
    });
  }
};

var loginStatus = {
  user: null,
  listeners: []
};

function callListeners() {
  for (var i = 0; i < loginStatus.listeners.length; i++) {
    loginStatus.listeners[i](loginStatus.user != null, loginStatus.user);
  }
}

export default {
  firebaseui: firebaseUiMock,
  onAuthStateChanged: callback => {
    loginStatus.listeners.push(callback);
    if (loginStatus.user != null) {
      callback(true, loginStatus.user);
    } else {
      callback(false);
    }
  },
  logout: callback => {
    loginStatus.user = null;
    callListeners();
    callback(true);
  },
  signInOptions: ["user", "mail", "phone"]
};
