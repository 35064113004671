<template>
  <md-field class="md-has-value">
    <label>Country</label>
    <select class="country md-input" @change="onChange">
      <option
        :selected="countryVal.key === country"
        v-for="countryVal in countries"
        :key="countryVal.key"
        :value="countryVal.key"
      >
        {{ countryVal.name }} ({{ countryVal.key }})
      </option>
    </select>
  </md-field>
</template>
<script>
import ConstantService from "@/services/ConstantService";

export default {
  name: "choose-country",
  props: ["country"],
  data() {
    return {
      countries: []
    };
  },
  methods: {
    onChange(event) {
      this.$emit("onCountryChange", event.target.value);
    }
  },
  created: function() {
    ConstantService.listCounties()
      .then(result => {
        this.countries = result;
      })
      .catch(error => {
        this.resultError = error;
      });
  }
};
</script>
