<template>
  <div v-if="resultError != null">
    <form-error-list v-if="errorList.length > 0" :errors="errorList" />
    <action-error :message="errorMessage" @close="errorMessage = null" />
  </div>
</template>
<script>
import ActionError from "./ActionError";
import FormErrorList from "./FormErrorList";

export default {
  name: "form-errors",
  props: ["resultError"],
  data() {
    return {
      errorList: [],
      errorMessage: null
    };
  },
  components: {
    ActionError,
    FormErrorList
  },
  beforeUpdate: function() {
    this.errorList = [];
    this.errorMessage = null;
    if (this.resultError == null) {
      return;
    }
    console.error(this.resultError);
    if (this.resultError.error == null) {
      this.errorMessage =
        "Ups! Somerhing is completely wrong! Connect admins! " +
        this.resultError;
      return;
    }
    if (this.resultError.status != null) {
      this.errorMessage = this.resultError.status;
    }
    if (this.resultError.error.message != null) {
      if (this.errorMessage != null) {
        this.errorMessage += " ";
      }
      this.errorMessage += this.resultError.error.message;
    }
    for (let i in this.resultError.error.errors) {
      var error = this.resultError.error.errors[i];
      var message = "";
      if (error.property != null && error.property.length > 1) {
        for (let pi = 0; pi < error.property.length; pi++) {
          let chacacter = error.property.charAt(pi);
          if (pi == 0) {
            chacacter = chacacter.toUpperCase();
          } else if (chacacter == chacacter.toUpperCase()) {
            message += " ";
          }
          message += chacacter;
        }
      }
      if (error.error != null) {
        if (message.length > 0) message += " ";
        message += error.error;
      }
      this.errorList.push(message);
    }
  }
};
</script>
