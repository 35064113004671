<template>
  <md-field class="md-has-value">
    <label>Timezone</label>
    <select class="timezoneGroup md-input" @change="onChange">
      <optgroup
        v-for="timezoneGroup in timezones"
        :key="timezoneGroup.label"
        :label="timezoneGroup.label"
      >
        <option
          :selected="timeZoneValue.label === timezone"
          v-for="timeZoneValue in timezoneGroup.values"
          :key="timeZoneValue.label"
        >
          {{ timeZoneValue.label }}
        </option>
      </optgroup>
    </select>
  </md-field>
</template>
<script>
import ConstantService from "@/services/ConstantService";

export default {
  name: "choose-time-zone",
  props: ["timezone"],
  data() {
    return {
      timezones: {}
    };
  },
  methods: {
    onChange(event) {
      this.$emit("onTimezoneChange", event.target.value);
    }
  },
  created: function() {
    ConstantService.listTimezones()
      .then(result => {
        this.timezones = result;
      })
      .catch(error => {
        this.resultError = error;
      });
  }
};
</script>
