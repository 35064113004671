<template>
  <md-icon class="done-icon"
    >{{ $icons.done }}<md-tooltip>{{ toolTip }}</md-tooltip></md-icon
  >
</template>
<script>
export default {
  name: "done-icon",
  props: {
    toolTip: {
      type: String,
      default: "Done"
    }
  }
};
</script>
