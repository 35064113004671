<template>
  <md-card v-if="Object.keys(scopes).length > 0">
    <md-card-header>
      <md-card-header-text>
        <div class="md-title">
          Generate api keys
        </div>
      </md-card-header-text>
    </md-card-header>
    <md-card-content>
      <md-checkbox
        v-for="(description, key) in scopes"
        :key="key"
        v-model="selected"
        :value="key"
        >{{ description }}</md-checkbox
      >
      <md-card-actions md-alignment="right">
        <md-button @click="doGenerate()" v-if="selected.length > 0"
          >Generate</md-button
        >
        <span v-if="selected.length === 0"
          >You have to choose at least one context</span
        >
      </md-card-actions>
    </md-card-content>
  </md-card>
</template>

<script>
export default {
  name: "api-key-generation",
  props: ["scopes", "onGenerate"],
  data() {
    return {
      selected: []
    };
  },
  methods: {
    doGenerate: function() {
      this.onGenerate([...this.selected]);
      this.selected = [];
    }
  }
};
</script>
