<template>
  <span
    v-if="value"
    class="md-layout-item md-alignment-top-right created-timestamp"
    >{{ value
    }}<md-tooltip md-direction="top" v-if="tooltip">{{
      tooltip
    }}</md-tooltip></span
  >
</template>
<script>
const resolveDates = (value, prefix) => {
  if (!value) return null;
  const dateValue = new Date(value);
  const dateString = [
    dateValue.getFullYear(),
    dateValue.getMonth() + 1,
    dateValue.getDate()
  ]
    .map(e => e.toString())
    .map(e => (e.length <= 1 ? `0${e}` : e))
    .join("-");
  return [
    `${prefix} at ${dateValue.toString()}`,
    dateString,
    `${prefix} at ${dateString}`
  ];
};
export default {
  name: "created-at-in-list",
  props: ["item", "showPrefix"],
  data: function() {
    return {
      value: null,
      tooltip: null
    };
  },
  created: function() {
    if (!this.item) {
      return;
    }
    this.value = null;
    this.tooltip = null;
    const values = [
      resolveDates(this.item.createdAt, "Created"),
      resolveDates(this.item.updatedAt, "Updated")
    ].filter(e => e !== null);
    if (values.length > 0) {
      if (this.showPrefix) {
        this.value = values[0][2];
      } else {
        this.value = values[0][1];
      }
      this.tooltip = values.map(e => e[0]).join(", ");
    }
  }
};
</script>
