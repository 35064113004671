<template>
  <md-menu-item
    v-if="organisation != null && organisation.id != null"
    :to="{ name: 'List Groups', params: { organisation_id: organisation.id } }"
  >
    <span>{{ organisation.name }} groups</span>
    <group-icon />
  </md-menu-item>
</template>
<script>
export default { name: "group-list-menu-item", props: ["organisation"] };
</script>
