<template>
  <md-field class="md-has-value">
    <label>Choose type</label>
    <select class="structure-type md-input" @change="onChange">
      <option
        :selected="typeVal.key === value"
        v-for="typeVal in values"
        :key="typeVal.key"
        :value="typeVal.key"
      >
        {{ typeVal.label }} ({{ typeVal.key }})
      </option>
    </select>
  </md-field>
</template>
<script>
import ConstantService from "@/services/ConstantService";

export default {
  name: "structure-type-choice",
  props: ["structureKey", "value"],
  data: function() {
    return {
      values: []
    };
  },
  methods: {
    onChange(event) {
      this.$emit("onStructureTypeChange", event.target.value);
    }
  },
  created: async function() {
    const languages = await ConstantService.listStructureTypes();
    this.values = languages[this.structureKey];
    if (this.value === undefined || this.value === null) {
      this.$emit("onStructureTypeChange", this.values[0].key);
    }
  }
};
</script>
