<template>
  <main-app-container
    :title="titleName"
    :icon="$icons.organisation"
    save_text="Save organisation"
    :organisation="edit"
  >
    <md-progress-bar
      class="md-accent"
      md-mode="indeterminate"
      v-if="this.loading"
    ></md-progress-bar>
    <form-errors :resultError="resultError" />
    <md-card v-if="!this.loading">
      <organisation-style-edit
        @onImageChange="onImageChange"
        @onTimezoneChange="onTimezoneChange"
        @onLanguagesChanged="onLanguagesChanged"
        @onCountryChange="onCountryChange"
        @onStructureTypeChange="onStructureTypeChange"
        :image-url="imageUrl"
        :edit="edit"
      />

      <organisation-edit
        @onImageChange="onImageChange"
        @onTimezoneChange="onTimezoneChange"
        @onLanguagesChanged="onLanguagesChanged"
        @onCountryChange="onCountryChange"
        @onStructureTypeChange="onStructureTypeChange"
        :edit="edit"
      />
      <md-card-actions md-alignment="right">
        <organisation-user-list-button :organisation="edit" />
        <group-list-button :organisation="edit" />
        <md-button @click="onSave()" v-if="edit.id == null">Save</md-button>
        <md-button @click="onSave()" v-else>Update</md-button>
      </md-card-actions>
    </md-card>
    <feature-list classifier="organisation" :id="edit.id" />
  </main-app-container>
</template>
<script>
import { OrganisationEdit } from "@/components/Organisation/";
import OrganisationService from "@/services/OrganisationService";
import FeatureList from "../../components/Feature/FeatureLongTypeList";
import OrganisationStyleEdit from "../../components/Organisation/OrganisationStyleEdit";

export default {
  name: "organisation-page",
  props: ["organisation_id"],
  components: {
    OrganisationStyleEdit,
    FeatureList,
    OrganisationEdit
  },
  data() {
    return {
      loading: true,
      edit: {},
      resultError: null,
      groups: [],
      image: null,
      imageUrl: null
    };
  },
  computed: {
    titleName: function() {
      return OrganisationService.getOrganisationName(
        this.edit,
        "",
        "New Organisation"
      );
    }
  },
  methods: {
    setEditData: function(data) {
      this.edit = data;
    },
    onSave: async function() {
      this.loading = true;
      this.resultError = null;
      try {
        if (this.edit.language) {
          this.edit.language = this.edit.language.key;
        }
        const result = await OrganisationService.save(this.edit.id, this.edit);
        if (this.image !== null) {
          try {
            await OrganisationService.saveImage(result.result.id, this.image);
          } catch (imageError) {
            // ignore
          }
        }
        if (this.edit.id == null) {
          this.edit = result.result;
          this.$router.push({
            name: "Edit Organisation",
            params: { organisation_id: result.result.id }
          });
        } else {
          this.setEditData(result.result);
        }
      } catch (error) {
        this.resultError = error;
      }
      this.loading = false;
    },
    onImageChange: function(image) {
      this.image = image;
    },
    onTimezoneChange: function(timezone) {
      this.edit.timezone = timezone;
    },
    onLanguagesChanged: function(language) {
      this.edit.language = language;
    },
    onCountryChange: function(country) {
      this.edit.country = country;
    },
    onStructureTypeChange: function(type) {
      this.edit.type = type;
    }
  },
  created: async function() {
    this.loading = true;
    if (this.organisation_id == null) {
      this.edit = {
        timezone: "Europe/Tallinn"
      };
    }
    try {
      const result = await OrganisationService.get(this.organisation_id);
      this.setEditData(result.result);
      try {
        this.imageUrl = await OrganisationService.getFullImageUrl(this.edit.id);
      } catch (imageError) {
        this.resultError = imageError;
      }
    } catch (error) {
      this.resultError = error;
    }
    this.loading = false;
  }
};
</script>
