<template>
  <div class="hello">
    <picture-input
      ref="pictureInput"
      @change="onChange"
      width="250"
      height="250"
      margin="16"
      accept="image/jpeg,image/png"
      size="10"
      :prefill="imageUrl"
      buttonClass="btn"
      :customStrings="{
        upload: '<h1>Bummer!</h1>',
        drag: 'Drag a photo'
      }"
    >
    </picture-input>
  </div>
</template>
<script>
import PictureInput from "vue-picture-input";
export default {
  name: "cb-image-upload",
  props: ["imageUrl"],
  components: {
    PictureInput
  },
  data() {
    return {
      image: null
    };
  },
  methods: {
    onChange(image) {
      if (image) {
        this.$emit("onImageChange", image);
      } else {
        console.log("FileReader API not supported: use the <form>, Luke!");
      }
    }
  }
};
</script>
