<template>
  <main-app-container
    title="Edit profile"
    :icon="$icons.user_profile_edit"
    save_text="Save profile"
  >
    <form-errors :resultError="resultError" />
    <md-card>
      <md-toolbar :md-elevation="1">
        <span class="md-title">Edit your profile</span>
      </md-toolbar>
      <md-card-content class="md-primary">
        <profile-edit
          :profile="edit"
          @onImageChange="onImageChange"
          :image-url="imageUrl"
          @onLanguagesChanged="lang => (language = lang)"
        />
      </md-card-content>
      <md-card-actions md-alignment="right">
        <md-button @click="onSave()" v-if="edit.id == null">Save</md-button>
        <md-button @click="onSave()" v-else>Update</md-button>
      </md-card-actions>
    </md-card>
  </main-app-container>
</template>
<script>
import UserProfileService from "@/services/UserProfileService";
import ProfileEdit from "../../components/UserProfile/UserProfileEdit";
import UserService from "@/services/UserService";

export default {
  name: "user-my-profile-page",
  components: {
    ProfileEdit
  },
  data() {
    return {
      loading: [],
      edit: {},
      changes: {},
      resultError: null,
      image: null,
      imageUrl: null,
      language: null
    };
  },
  computed: {
    dataChanged: function() {
      return false;
    }
  },
  methods: {
    fixProfileChoice: function(editResult) {
      if (this.edit == null || this.edit.gender == null) {
        editResult.genderChoice = null;
        this.edit = editResult;
      }
      if (editResult.gender === "female" || editResult.gender === "male") {
        editResult.genderChoice = this.edit.gender;
      } else {
        editResult.genderChoice = "other";
      }
      this.edit = editResult;
    },
    onSave: async function() {
      this.resultError = null;
      if (
        this.edit.genderChoice === "female" ||
        this.edit.genderChoice === "male"
      ) {
        this.edit.gender = this.edit.genderChoice;
      }
      try {
        const result = await UserProfileService.saveYourself(this.edit);
        this.fixProfileChoice(result.result);
        if (this.image != null) {
          await UserProfileService.saveYourImage(this.image);
        }
        if (this.language !== null) {
          UserService.setMyLanguage(this.language.key);
          this.language = null;
        }
      } catch (error) {
        this.resultError = error;
      }
    },
    onImageChange: function(image) {
      this.image = image;
    }
  },
  created: async function() {
    this.edit = {};
    this.changes = {};
    try {
      const result = await UserProfileService.getYourself();
      this.fixProfileChoice(result.result);
      try {
        this.imageUrl = await UserProfileService.getFullImageUrl();
      } catch (error1) {
        this.resultError = error;
      }
    } catch (error) {
      if (error.status === 404) {
        this.edit = {};
      } else {
        this.resultError = error;
      }
    }
  }
};
</script>
