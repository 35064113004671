<template>
  <hide-show-card title="Features" id="features">
    <md-card-content class="md-gutter">
      <md-empty-state
        :md-icon="$icons.features"
        md-description="No features defined!"
        v-if="features.length === 0 && !loading"
      />
      <md-progress-bar
        class="md-accent"
        md-mode="indeterminate"
        v-if="loading"
      />
      <md-list v-else>
        <md-list-item v-for="(feature, index) in features" :key="index">
          <span class="md-list-item-text" v-if="feature.linkId">{{
            feature.feature
          }}</span>
          <span class="md-list-item-text md-disabled" v-else>{{
            feature.feature
          }}</span>
          <!-- TODO: this is for future!
          <md-switch
            :value="!feature.enabled"
            v-if="feature.linkId"
            @change="setItem(feature, !feature.enabled)"
          />
          <md-switch :value="!feature.enabled" v-else disabled />
-->
          <md-button
            class="md-icon-button"
            v-if="feature.linkId"
            @click="removeItem(feature)"
          >
            <delete-icon tool-tip="Remove feature" />
          </md-button>
          <md-button
            class="md-icon-button"
            v-else
            @click="setItem(feature, feature.enabled)"
          >
            <add-icon tool-tip="Add feature" />
          </md-button>
        </md-list-item>
      </md-list>
    </md-card-content>
  </hide-show-card>
</template>

<script>
import FeatureService from "../../services/FeatureService";
import HideShowCard from "@/components/Layout/HideShowCard";

export default {
  name: "feature-list",
  props: ["classifier", "id"],
  data() {
    return {
      features: [],
      loading: true
    };
  },
  components: {
    HideShowCard
  },
  methods: {
    setItem: function(item, enabled) {
      FeatureService.setFeatureForLongId(
        this.classifier,
        this.id,
        item.feature,
        enabled
      )
        .then(result => {
          this.features = this.features.map(feature => {
            const foundFiltered = result.result.filter(
              res => res.feature === feature.feature
            );
            if (foundFiltered.length > 0) {
              return foundFiltered[0];
            }
            return feature;
          });
        })
        .catch(error => (this.resultError = error));
    },
    removeItem: function(item) {
      FeatureService.deleteFeatureForLongId(
        this.classifier,
        this.id,
        item.feature
      )
        .then(result => {
          this.features = this.features.map(feature => {
            const foundFiltered = result.result.filter(
              res => res.feature === feature.feature
            );
            if (foundFiltered.length > 0) {
              return {
                enabled: feature.enabled,
                feature: feature.feature,
                classifiers: []
              };
            }
            return feature;
          });
        })
        .catch(error => (this.resultError = error));
    },
    fetchData: async function() {
      this.loading = true;
      if (!this.id || !this.classifier) {
        return;
      }
      try {
        this.features = await FeatureService.mergeLongFeatures(
          this.classifier,
          this.id
        );
      } catch (error) {
        this.resultError = error;
      }
      this.loading = false;
    }
  },
  created: function() {
    this.fetchData();
  },
  watch: {
    $route: "fetchData",
    $props: {
      handler() {
        this.fetchData();
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
