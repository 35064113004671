import Api from "./api/RestAPI";
import { PaginatedStreamDataIterator } from "@/services/api/ResultDataIterator";

export default {
  getOrganisationListIterator() {
    return new PaginatedStreamDataIterator(
      "/api/user/v2/membership/organisations"
    );
  },
  getUserMembership(user_id) {
    return Api.get(`/api/user/v2/membership/${user_id}`);
  },
  addUserRoleToOrganisation(organisation_id, user_id, role) {
    if (role === null || role === undefined) {
      return Api.post(
        `/api/user/v2/membership/${user_id}/organisation/${organisation_id}`
      );
    }
    return Api.post(
      `/api/user/v2/membership/${user_id}/organisation/${organisation_id}/role/${role}`
    );
  },
  removeUserRoleFromOrganisation(organisation_id, user_id, role) {
    return Api.delete(
      `/api/user/v2/membership/${user_id}/organisation/${organisation_id}/role/${role}`
    );
  },
  addUserRoleToGroup(group_id, user_id, role) {
    return Api.post(
      `/api/user/v2/membership/${user_id}/group/${group_id}/role/${role}`
    );
  },
  removeUserRoleFromGroup(group_id, user_id, role) {
    return Api.delete(
      `/api/user/v2/membership/${user_id}/group/${group_id}/role/${role}`
    );
  }
};
