<template>
  <md-button
    :disabled="disabled"
    class="md-icon-button"
    @click="$emit('click')"
  >
    <md-icon v-if="value" class="delete-icon"
      >check_box<md-tooltip>{{ toolTipEnabled }}</md-tooltip></md-icon
    >
    <md-icon v-else class="delete-icon"
      >check_box_outline_blank<md-tooltip>{{
        toolTipDisabled
      }}</md-tooltip></md-icon
    >
  </md-button>
</template>
<script>
export default {
  name: "enable-disable-button",
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default() {
        return false;
      }
    },
    value: {
      type: Boolean
    },
    toolTipEnabled: {
      type: String,
      required: true
    },
    toolTipDisabled: {
      type: String,
      required: true
    }
  }
};
</script>
