<template>
  <main-app-container title="Interests & Bucket List" :icon="$icons.lists">
    <form-errors :resultError="resultError" />
    <md-dialog-confirm
      :md-active.sync="showConfirmation"
      :md-title="confirmationTitle"
      md-content="This process is irreversible. Once removed it will be removed from all te profiles!"
      md-confirm-text="OK"
      md-cancel-text="Cancel"
      @md-confirm="doRemove"
    />
    <md-card>
      <md-toolbar>
        <md-radio v-model="showContent" value="all">All</md-radio>
        <md-radio v-model="showContent" value="interest">Interests</md-radio>
        <md-radio v-model="showContent" value="bucket">Bucket list</md-radio>
      </md-toolbar>
      <md-card-content class="md-primary md-layout md-gutter">
        <InterestsContainer
          :showContent="
            !loading && (showContent === 'interest' || showContent === 'all')
          "
          :values="interests"
          :predefinedValues="predefinedInterests"
          title="Interests"
          notFoundMessage="No intererests found!"
          @onDelete="
            item =>
              (deletableItem = {
                id: item.id,
                type: 'interest',
                value: item.value
              })
          "
        />
        <InterestsContainer
          :showContent="
            !loading && (showContent === 'bucket' || showContent === 'all')
          "
          :values="bucketList"
          :predefinedValues="predefinedBucketList"
          title="Bucket list"
          notFoundMessage="No bucket list items found!"
          @onDelete="
            item =>
              (deletableItem = {
                id: item.id,
                type: 'bucket',
                value: item.value
              })
          "
        />
      </md-card-content>
    </md-card>
  </main-app-container>
</template>
<script>
import InterestsAndBucketListService from "@/services/InterestsAndBucketListService";
import { InterestsContainer } from "@/components/InterestsBucketList/";

const sortDate = (item1, item2) => {
  if (item1.createdAt === undefined && item2.createdAt === undefined) return 0;
  if (item1.createdAt !== undefined && item2.createdAt === undefined) return -1;
  if (item1.createdAt === undefined && item2.createdAt !== undefined) return 1;
  const date1 = new Date(item1.createdAt);
  const date2 = new Date(item2.createdAt);
  return date2 - date1;
};

export default {
  name: "interests-bucket-list-page",
  components: {
    InterestsContainer
  },
  data() {
    return {
      showContent: "all",
      interestsVisibility: "created",
      bucketListVisibility: "created",
      deletableItem: undefined,
      loading: true,
      bucketList: [],
      predefinedBucketList: [],
      interests: [],
      predefinedInterests: [],
      resultError: null
    };
  },
  methods: {
    doWithLoading: function(callback) {
      this.loading = true;
      callback()
        .catch(error => (this.resultError = error))
        .finally(() => (this.loading = false));
    },
    doRemove: function() {
      if (this.deletableItem === undefined) {
        console.error("Could not delete item as it is null!");
        return;
      }
      const id = this.deletableItem.id;
      const type = this.deletableItem.type;
      this.doWithLoading(async () => {
        await InterestsAndBucketListService.bucketList.delete(id);
        if (type === "interest") {
          this.interests = [...this.interests].filter(item => item.id !== id);
        } else if (type === "bucket") {
          this.bucketList = [...this.bucketList].filter(item => item.id !== id);
        }
      });
    }
  },
  computed: {
    showConfirmation: {
      get: function() {
        return this.deletableItem !== undefined;
      },
      set: function(val) {
        if (val) {
          console.error("The value should not be true");
          return;
        }
        this.deletableItem = undefined;
      }
    },
    confirmationTitle: function() {
      if (this.deletableItem !== undefined) {
        return `Do you want to remove "${this.deletableItem.value}"?`;
      }
      return "";
    }
  },
  created: async function() {
    this.bucketList = [];
    this.interests = [];
    this.doWithLoading(async () => {
      const interestsResult = await InterestsAndBucketListService.interests.list();
      this.interests = interestsResult.result
        .filter(item => item.createdAt !== undefined)
        .sort(sortDate);
      this.predefinedInterests = interestsResult.result
        .filter(item => item.createdAt === undefined)
        .sort(sortDate);

      const bucketListResult = await InterestsAndBucketListService.bucketList.list();
      this.bucketList = bucketListResult.result
        .filter(item => item.createdAt !== undefined)
        .sort(sortDate);
      this.predefinedBucketList = bucketListResult.result
        .filter(item => item.createdAt === undefined)
        .sort(sortDate);
    });
  }
};
</script>
