import Api from "./api/RestAPI";

export default {
  list(groupId) {
    return Api.get("/api/invitehash/v1/group/" + groupId);
  },
  create(groupId, hashLifeSpan) {
    return Api.post("/api/invitehash/v1/group/" + groupId, {
      lifespan: hashLifeSpan,
      keepOldInvitations: true
    });
  },
  delete(groupId, hashId) {
    return Api.delete(
      "/api/invitehash/v1/group/" + groupId + "/invitehash/" + hashId
    );
  }
};
