<template>
  <main-app-container title="Auto question" :icon="$icons.autoQuestions">
    <form-errors :resultError="resultError" />
    <md-card>
      <md-card-content class="md-primary md-layout md-gutter">
        <md-table>
          <md-table-row>
            <md-table-head
              v-for="language in languages"
              :key="'headc_' + language.id"
              >{{ language.label }}</md-table-head
            >
            <md-table-head></md-table-head>
          </md-table-row>
          <md-table-row>
            <md-table-cell
              v-for="language in languages"
              :key="'inputc_' + language.id"
            >
              <md-field>
                <label>{{ language.label }}</label>
                <md-input
                  :key="'input' + language.id"
                  v-model="newLanguage[language.id]"
                  @change="checkStatus()"
                  @keyup="checkStatus()"
                ></md-input>
              </md-field>
            </md-table-cell>
            <md-table-cell>
              <md-button @click="addLanguage()" v-if="canBeSaved"
                ><add-icon
              /></md-button>
            </md-table-cell>
          </md-table-row>
          <md-table-row
            v-for="question in questions"
            :key="question.id"
            @click="onEdit(question)"
          >
            <md-table-cell
              v-for="language in languages"
              :key="'trans_' + question.id + '_' + language.id"
            >
              <span v-if="editId !== question.id">{{
                question.translation[language.id]
              }}</span>
              <md-field v-if="editId === question.id">
                <label>{{ language.label }}</label>
                <md-input
                  :key="'edit' + language.id"
                  v-model="editData[language.id]"
                  @change="checkEditStatus()"
                  @keyup="checkEditStatus()"
                ></md-input>
              </md-field>
            </md-table-cell>
            <md-table-cell>
              <md-button
                v-if="editId !== question.id"
                @click="deleteLanguage(question.id)"
                ><delete-icon
              /></md-button>
              <md-button
                v-if="editId === question.id && canBeUpdated"
                @click="update()"
                ><done-icon
              /></md-button>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </md-card-content>
    </md-card>
  </main-app-container>
</template>
<script>
import AutoQuestionService from "@/services/AutoQuestionService";

export default {
  name: "auto-questions",
  data() {
    return {
      editId: null,
      edit: null,
      editData: {},
      canBeUpdated: false,
      newLanguage: {},
      questions: [],
      languages: [],
      resultError: null,
      canBeSaved: false
    };
  },
  methods: {
    onEdit: function(item) {
      if (this.editId === item.id) {
        return;
      }
      this.edit = item;
      this.editId = item.id;
      this.editData = {};
      const dataToEdit = {};
      this.languages.forEach(l => {
        dataToEdit[l.id] = item.translation[l.id];
      });
      this.editData = dataToEdit;
      this.checkEditStatus();
    },
    addLanguage: async function() {
      if (!this.canBeSaved) return;
      const result = await AutoQuestionService.create(this.newLanguage);
      this.newLanguage = {};
      this.questions = result.result.questions;
    },
    update: async function() {
      this.checkEditStatus();
      if (!this.canBeUpdated) return;
      const result = await AutoQuestionService.update(
        this.editId,
        this.editData
      );
      this.edit = null;
      this.editId = null;
      this.editData = {};
      this.questions = result.result.questions;
      this.checkEditStatus();
    },
    deleteLanguage: async function(id) {
      const result = await AutoQuestionService.delete(id);
      this.questions = result.result.questions;
    },
    checkEditStatus: function() {
      let status = true;
      this.languages.forEach(l => {
        if (!this.editData[l.id]) {
          status = false;
        }
      });
      this.canBeUpdated = status;
    },
    checkStatus: function() {
      let status = true;
      this.languages.forEach(l => {
        if (!this.newLanguage[l.id]) {
          status = false;
        }
      });
      this.canBeSaved = status;
    }
  },
  created: async function() {
    this.questions = [];
    const result = await AutoQuestionService.list();
    const langResult = result.result.languages;
    this.questions = result.result.questions;

    const languages = [];
    const newLangs = {};
    langResult.forEach(language => {
      Object.keys(language).forEach(id => {
        languages.push({ id: id, label: language[id] });
        newLangs[id] = null;
      });
    });
    this.newLanguage = newLangs;
    this.languages = languages;
  }
};
</script>
