import Api from "./api/RestAPI";

const ROOT_ENDPOINT = "/api/features/v1";

const getEndpoint = function() {
  return [...[ROOT_ENDPOINT], ...arguments].join("/");
};

export default {
  async mergeLongFeatures(classifier, id) {
    const allFeaturesResponse = await this.listFeatures(classifier);
    const objectFeaturesResponse = await this.listFeaturesForLongId(
      classifier,
      id
    );
    return allFeaturesResponse.result.map(feature => {
      const existing = objectFeaturesResponse.result.filter(
        objf => objf.feature === feature.feature
      );
      if (existing.length > 0) return existing[0];
      return feature;
    });
  },
  listAllFeatures() {
    return Api.get(ROOT_ENDPOINT);
  },
  listFeatures(classifier) {
    return Api.get(getEndpoint(classifier));
  },
  listFeaturesForLongId(classifier, id) {
    return Api.get(getEndpoint("long", classifier, id));
  },
  setFeatureForLongId(classifier, id, feature, isEnabled) {
    return Api.post(getEndpoint("long", classifier, id, feature, isEnabled));
  },
  deleteFeatureForLongId(classifier, id, feature) {
    return Api.delete(getEndpoint("long", classifier, id, feature));
  }
};
