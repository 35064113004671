<template>
  <md-menu-item
    v-if="
      group != null &&
        group.id != null &&
        organisation != null &&
        organisation.id != null
    "
    :to="{
      name: 'Edit Group',
      params: { group_id: group.id, organisation_id: organisation.id }
    }"
  >
    <span>{{ group.name }}</span>
    <group-icon />
  </md-menu-item>
</template>
<script>
export default { name: "group-menu-item", props: ["group", "organisation"] };
</script>
