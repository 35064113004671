<template>
  <md-card v-if="errors != null && errors.length > 0">
    <md-card-header>
      <md-card-header-text>
        <div class="md-title" v-if="errors.length == 1">
          Please correct the following error
        </div>
        <div class="md-title" v-else>Please correct the following errors</div>
      </md-card-header-text>
    </md-card-header>
    <md-card-content>
      <md-list class="form-errors">
        <md-list-item v-for="(error, index) in errors" :key="index">
          <span class="md-list-item-text">{{ error }}</span>
        </md-list-item>
      </md-list>
    </md-card-content>
  </md-card>
</template>
<script>
export default {
  name: "form-error-list",
  props: ["errors"]
};
</script>
