<template>
  <div class="page-container">
    <md-app md-mode="fixed" class="full-height">
      <md-app-toolbar class="md-primary" md-elevation="0" md-layout-nowrap>
        <div class="md-toolbar-row">
          <div class="md-toolbar-section-start">
            <md-button
              class="md-icon-button"
              @click="menuVisible = !menuVisible"
              v-if="!menuVisible"
            >
              <menu-icon />
            </md-button>
            <span class="md-title"
              ><md-icon class="title-icon">{{ icon }}</md-icon
              >{{ title }}</span
            >
          </div>
          <div class="md-toolbar-section-end">
            <search-input
              v-if="searchLabel"
              :label="searchLabel"
              @onSearchChange="item => $emit('onSearchChange', item)"
            />
            <md-menu md-size="big" md-direction="bottom-end">
              <md-button class="md-icon-button" md-menu-trigger>
                <user_profile_edit-icon tool-tip="Your profile" />
              </md-button>
              <md-menu-content>
                <md-menu-item to="/profile">
                  <span>Edit profile</span>
                  <user_profile_edit-icon />
                </md-menu-item>
                <md-menu-item to="/security" v-if="isSuperUser()">
                  <span>Security</span>
                  <security-icon />
                </md-menu-item>
                <md-menu-item @click="logout">
                  <span>Logout</span>
                  <logout-icon />
                </md-menu-item>
              </md-menu-content>
            </md-menu>
          </div>
        </div>
      </md-app-toolbar>
      <md-app-drawer :md-active.sync="menuVisible" md-persistent="mini">
        <md-toolbar class="md-transparent" md-elevation="0">
          <span class="md-title">Back Office</span>
          <div class="md-toolbar-section-end">
            <md-button
              class="md-icon-button md-dense"
              @click="menuVisible = !menuVisible"
            >
              <drawer-close-icon />
            </md-button>
          </div>
        </md-toolbar>
        <main-app-drawer-menu :organisation="organisation" :group="group" />
      </md-app-drawer>

      <md-app-content>
        <slot />
        <md-speed-dial class="md-bottom-right" v-if="add_text">
          <md-speed-dial-target @click="$emit('addItem')">
            <add-icon />
            <md-tooltip md-direction="top">{{ add_text }}</md-tooltip>
          </md-speed-dial-target>
        </md-speed-dial>
      </md-app-content>
    </md-app>
  </div>
</template>
<script>
import RestAPI from "@/services/api/RestAPI";
import MainAppDrawerMenu from "./MainAppDrawerMenu";
import SecurityIcon from "@/components/Layout/Icons/SecurityIcon";
import SearchInput from "@/components/Layout/SearchInput";

export default {
  props: ["title", "add_text", "icon", "organisation", "group", "searchLabel"],
  data: () => ({
    menuVisible: false,
    searchTimerInterval: null
  }),
  components: {
    SecurityIcon,
    MainAppDrawerMenu,
    SearchInput
  },
  computed: {
    hasOrganisation: function() {
      return this.organisation != null && this.organisation.id != null;
    },
    hasGroup: function() {
      return this.group != null && this.group.id != null;
    },
    showDivider: function() {
      return this.hasOrganisation || this.hasGroup;
    }
  },
  methods: {
    logout() {
      RestAPI.logout();
    },
    isSuperUser() {
      return RestAPI.hasRole("SUPERUSER");
    }
  },
  destroyed: function() {
    if (this.searchTimerInterval !== null) {
      clearTimeout(this.searchTimerInterval);
    }
  },
  name: "main-app-container"
};
</script>
<style lang="scss" scoped></style>
