import Vue from "vue";
import "@/components/Layout/Icons";
import "@/components/Layout/MenuItems";
import "@/components/Layout/Buttons";
import "@/components/Layout/Errors";
import "@/components/Layout/Time";

import MainAppContainer from "./MainAppContainer";
import EmptyState from "./EmptyState";
import ImageUpload from "./ImageUpload";

Vue.component(MainAppContainer.name, MainAppContainer);
Vue.component(EmptyState.name, EmptyState);
Vue.component(ImageUpload.name, ImageUpload);
