<template>
  <md-icon class="auto-questions.icon"
    >{{ $icons.autoQuestions }}<md-tooltip>{{ toolTip }}</md-tooltip></md-icon
  >
</template>
<script>
export default {
  name: "auto-questions.icon",
  props: {
    toolTip: {
      type: String,
      default: "Auto questions"
    }
  }
};
</script>
