const listeners = [];
const errorListeners = [];
const data = {
  isInitialized: false,
  isAuthorized: false
};

function fireChange(isAuthorized, payload) {
  if (payload != null && payload.error != null) {
    console.error(payload.error);
    for (let i = 0; i < errorListeners.length; i++) {
      errorListeners[i](payload.error);
    }
  }
  if (isAuthorized === data.isAuthorized && data.isInitialized) {
    return;
  }
  data.isAuthorized = isAuthorized;
  for (let i = 0; i < listeners.length; i++) {
    listeners[i].callback(isAuthorized);
    if (listeners[i].init) {
      listeners.splice(i, 1);
      i--;
    }
  }
  data.isInitialized = true;
}

export default {
  onError: callback => {
    errorListeners.push(callback);
  },
  onReady: callback => {
    if (data.isInitialized) {
      callback(data.isAuthorized);
    } else {
      listeners.push({ init: true, callback: callback });
    }
  },
  onChange: callback => {
    listeners.push({ init: false, callback: callback });
    if (data.isInitialized) {
      callback(data.isAuthorized);
    }
  },
  fireChange: (isAuthorized, payload) => fireChange(isAuthorized, payload)
};
