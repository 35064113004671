<template>
  <md-field>
    <label>{{ label }}</label>
    <md-input v-model="topSearchValue" v-on:keyup="onTopSearchKeyUp"></md-input>
    <md-button
      class="md-icon-button md-dense"
      @click="clearTopSearchValue"
      v-if="topSearchValue"
      ><md-icon>clear</md-icon></md-button
    >
    <md-icon v-else>search</md-icon>
  </md-field>
</template>
<script>
export default {
  props: ["label"],
  name: "search-input",
  data: () => ({
    topSearchValue: "",
    previousTopSearchValue: "",
    searchTimerInterval: null
  }),
  watch: {
    topSearchValue: function(newValue, oldValue) {
      if (newValue === oldValue || newValue === this.previousTopSearchValue) {
        return;
      }
      this.triggerTopSearchValue(newValue, false);
    }
  },
  methods: {
    clearTopSearchValue() {
      this.topSearchValue = "";
      this.previousTopSearchValue = "";
      if (this.searchTimerInterval !== null) {
        clearTimeout(this.searchTimerInterval);
      }
      this.$emit("onSearchChange", "");
    },
    triggerTopSearchValue(value, noTimeout) {
      if (value === null || value === undefined) return;
      const trimmedNewValue = value.trim();
      if (trimmedNewValue === this.previousTopSearchValue) {
        return;
      }
      if (this.searchTimerInterval !== null) {
        clearTimeout(this.searchTimerInterval);
      }
      this.previousTopSearchValue = trimmedNewValue;
      if (noTimeout) {
        this.$emit("onSearchChange", trimmedNewValue);
      } else {
        this.searchTimerInterval = setTimeout(
          () => this.$emit("onSearchChange", trimmedNewValue),
          1000
        );
      }
    },
    onTopSearchKeyUp(keyEvent) {
      if (keyEvent.key !== "Enter") {
        return;
      }
      this.triggerTopSearchValue(this.topSearchValue, true);
    },
    logout() {
      RestAPI.logout();
    },
    isSuperUser() {
      return RestAPI.hasRole("SUPERUSER");
    }
  },
  destroyed: function() {
    if (this.searchTimerInterval !== null) {
      clearTimeout(this.searchTimerInterval);
    }
  }
};
</script>
<style lang="scss" scoped></style>
