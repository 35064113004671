<template>
  <md-icon class="users-icon"
    >{{ $icons.users }}<md-tooltip>{{ toolTip }}</md-tooltip></md-icon
  >
</template>
<script>
export default {
  name: "users-icon",
  props: {
    toolTip: {
      type: String,
      default: "Users"
    }
  }
};
</script>
