import Api from "@/services/api/RestAPI";

export default {
  interests: {
    list: () => {
      return Api.get("/api/constants/interests/v1/all");
    },
    delete: id => {
      return Api.delete("/api/constants/interests/v1/all/" + id);
    }
  },
  bucketList: {
    list: () => {
      return Api.get("/api/constants/bucket_list/v1/all");
    },
    delete: id => {
      return Api.delete("/api/constants/bucket_list/v1/all/" + id);
    }
  }
};
