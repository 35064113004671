<template>
  <md-snackbar
    md-position="center"
    :md-duration="Infinity"
    :md-active="showSnackbar"
    md-persistent
  >
    <span>{{ message }}</span>
    <md-button class="md-primary" @click="$emit('close')">OK</md-button>
  </md-snackbar>
</template>
<script>
export default {
  name: "action-error",
  props: ["message"],
  computed: {
    showSnackbar: function() {
      return this.message != null;
    }
  }
};
</script>
