<template>
  <hide-show-card title="Check In management" id="checkIn">
    <md-card-content class="md-gutter">
      <md-empty-state
        :md-icon="$icons.checkin"
        md-description="No check in defined!"
        v-if="checkInResult.length === 0 && !loading"
      />
      <md-progress-bar
        class="md-accent"
        md-mode="indeterminate"
        v-if="loading"
      />
      <md-card v-else v-for="checkIn in checkInResult" :key="checkIn.id">
        <md-card-header>
          <div class="md-title">
            <md-field>
              <label>Change notification</label>
              <md-input v-model="checkIn.question" />
              <md-button
                class="md-icon-button "
                @click="
                  changeCheckinQuestionDefinition(checkIn.id, checkIn.question)
                "
              >
                <done-icon tool-tip="Change" />
              </md-button>
              <enable-disable-button
                tool-tip-disabled="Enable nudge"
                tool-tip-enabled="Disable nudge"
                :value="checkIn.enabled"
                @click="enableQuestionDefinition(checkIn.id, !checkIn.enabled)"
              />
              <delete-button
                tool-tip="Remove nudge"
                @click="removeQuestionDefinition(checkIn.id)"
              />
            </md-field>
          </div>
        </md-card-header>
        <md-card-content>
          <md-divider></md-divider>
          <CheckInQuestions
            :questions="checkIn.questions"
            @onCheckInQuestionAdded="
              value => newCheckInQuestion(checkIn.id, value)
            "
            @onCheckInQuestionRemoved="
              value => removeQuestion(checkIn.id, value)
            "
            @onCheckInQuestionChanged="
              value => changeQuestion(checkIn.id, value)
            "
            @onCheckInQuestionEnabled="
              value => enableQuestion(checkIn.id, value)
            "
          />
          <md-divider></md-divider>
          <CheckInSchedulers
            :schedulers="checkIn.schedulers"
            @onCheckInSchedulerAdded="value => newScheduler(checkIn.id, value)"
            @onCheckInSchedulerRemoved="
              value => removeScheduler(checkIn.id, value)
            "
            @onCheckInSchedulerEnabled="
              value => enableScheduler(checkIn.id, value)
            "
          />
        </md-card-content>
      </md-card>

      <md-list>
        <md-list-item>
          <md-field>
            <label>New check in group</label>
            <md-input v-model="newCheckInGroupName" />
          </md-field>
          <md-button class="md-icon-button" @click="addNewCheckInGroup()">
            <add-icon tool-tip="Add new check in group" />
          </md-button>
        </md-list-item>
      </md-list>
    </md-card-content>
  </hide-show-card>
</template>

<script>
import CheckInService from "../../services/CheckInService";
import HideShowCard from "@/components/Layout/HideShowCard";
import CheckInQuestions from "@/components/CheckIn/CheckInQuestions";
import CheckInSchedulers from "@/components/CheckIn/CheckInSchedulers";

export default {
  name: "check-in-items",
  props: ["classifier", "id"],
  data() {
    return {
      checkInResult: [],
      loading: true,
      newCheckInGroupName: null,
      newQuestionValue: null
    };
  },
  components: {
    CheckInSchedulers,
    CheckInQuestions,
    HideShowCard
  },
  methods: {
    doWithLoading: async function(callback) {
      this.loading = true;
      try {
        return await callback();
      } catch (error) {
        this.resultError = error;
      } finally {
        this.loading = false;
      }
    },
    doWithLoadingAndReplaceItem: async function(callback) {
      return await this.doWithLoading(async () => {
        const result = await callback();
        const item = result.result;
        this.checkInResult = this.checkInResult.map(e =>
          e.id === item.id ? item : e
        );
      });
    },
    addNewCheckInGroup: async function() {
      const result = await this.doWithLoading(async () => {
        const r = await CheckInService.createQuestionDefinition(
          this.id,
          this.newCheckInGroupName
        );
        this.newCheckInGroupName = null;
        return r;
      });
      this.checkInResult.push(result.result);
    },
    removeQuestionDefinition: function(itemId) {
      this.doWithLoading(async () => {
        await CheckInService.removeQuestionDefinition(itemId);
        this.checkInResult = this.checkInResult.filter(e => e.id !== itemId);
      });
    },
    changeCheckinQuestionDefinition: async function(checkInId, question) {
      await this.doWithLoadingAndReplaceItem(() =>
        CheckInService.changeQuestionDefinition(checkInId, question)
      );
    },
    enableQuestionDefinition: async function(checkInId, enabled) {
      await this.doWithLoadingAndReplaceItem(() =>
        CheckInService.enableQuestionDefinition(checkInId, enabled)
      );
    },
    newCheckInQuestion: async function(checkInId, question) {
      await this.doWithLoadingAndReplaceItem(() =>
        CheckInService.addQuestion(checkInId, question)
      );
    },
    removeQuestion: async function(checkInId, questionId) {
      await this.doWithLoadingAndReplaceItem(() =>
        CheckInService.removeQuestion(checkInId, questionId)
      );
    },
    changeQuestion: async function(checkInId, question) {
      await this.doWithLoadingAndReplaceItem(() =>
        CheckInService.changeQuestion(checkInId, question.id, question.question)
      );
    },
    enableQuestion: async function(checkInId, payload) {
      await this.doWithLoadingAndReplaceItem(() =>
        CheckInService.enableQuestion(checkInId, payload.id, payload.enabled)
      );
    },
    newScheduler: async function(checkInId, value) {
      await this.doWithLoadingAndReplaceItem(() =>
        CheckInService.addScheduler(checkInId, value.dayOfWeek, value.when)
      );
    },
    removeScheduler: async function(checkInId, schedulerId) {
      await this.doWithLoadingAndReplaceItem(() =>
        CheckInService.removeScheduler(checkInId, schedulerId)
      );
    },
    enableScheduler: async function(checkInId, payload) {
      await this.doWithLoadingAndReplaceItem(() =>
        CheckInService.enableScheduler(checkInId, payload.id, payload.enabled)
      );
    },
    fetchData: async function() {
      this.loading = true;
      if (!this.id || !this.classifier) {
        return;
      }
      try {
        const result = await CheckInService.findQuestionDefinitionForGroup(
          this.id
        );
        this.checkInResult = result.result;
      } catch (error) {
        this.resultError = error;
      }
      this.loading = false;
    }
  },
  created: function() {
    this.fetchData();
  },
  watch: {
    $route: "fetchData",
    $props: {
      handler() {
        this.fetchData();
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
