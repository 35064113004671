<template>
  <md-list-item
    v-if="isVisible"
    :to="{
      name: link,
      params: params
    }"
  >
    <slot />
    <span class="md-list-item-text">{{ text }}</span>
  </md-list-item>
</template>
<script>
export default {
  props: ["text", "link", "organisation", "group", "user"],
  computed: {
    hasGroup: function() {
      return this.group != null && this.group.id != null;
    },
    hasOrganisation: function() {
      return this.organisation != null && this.organisation.id != null;
    },
    hasUser: function() {
      return this.user != null && this.user.id != null;
    },
    isVisible: function() {
      if (this.text == null) return false;
      return this.hasGroup || this.hasOrganisation || this.hasUser;
    },
    params: function() {
      let result = {};
      if (this.hasOrganisation) {
        result["organisation_id"] = this.organisation.id;
      }
      if (this.hasGroup) {
        result["group_id"] = this.group.id;
      }
      if (this.hasUser) {
        result["user_id"] = this.user.id;
      }
      return result;
    }
  },
  name: "list-item-link"
};
</script>
