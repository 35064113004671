var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main-app-container',{attrs:{"title":"Interests & Bucket List","icon":_vm.$icons.lists}},[_c('form-errors',{attrs:{"resultError":_vm.resultError}}),_c('md-dialog-confirm',{attrs:{"md-active":_vm.showConfirmation,"md-title":_vm.confirmationTitle,"md-content":"This process is irreversible. Once removed it will be removed from all te profiles!","md-confirm-text":"OK","md-cancel-text":"Cancel"},on:{"update:mdActive":function($event){_vm.showConfirmation=$event},"update:md-active":function($event){_vm.showConfirmation=$event},"md-confirm":_vm.doRemove}}),_c('md-card',[_c('md-toolbar',[_c('md-radio',{attrs:{"value":"all"},model:{value:(_vm.showContent),callback:function ($$v) {_vm.showContent=$$v},expression:"showContent"}},[_vm._v("All")]),_c('md-radio',{attrs:{"value":"interest"},model:{value:(_vm.showContent),callback:function ($$v) {_vm.showContent=$$v},expression:"showContent"}},[_vm._v("Interests")]),_c('md-radio',{attrs:{"value":"bucket"},model:{value:(_vm.showContent),callback:function ($$v) {_vm.showContent=$$v},expression:"showContent"}},[_vm._v("Bucket list")])],1),_c('md-card-content',{staticClass:"md-primary md-layout md-gutter"},[_c('InterestsContainer',{attrs:{"showContent":!_vm.loading && (_vm.showContent === 'interest' || _vm.showContent === 'all'),"values":_vm.interests,"predefinedValues":_vm.predefinedInterests,"title":"Interests","notFoundMessage":"No intererests found!"},on:{"onDelete":function (item) { return (_vm.deletableItem = {
              id: item.id,
              type: 'interest',
              value: item.value
            }); }}}),_c('InterestsContainer',{attrs:{"showContent":!_vm.loading && (_vm.showContent === 'bucket' || _vm.showContent === 'all'),"values":_vm.bucketList,"predefinedValues":_vm.predefinedBucketList,"title":"Bucket list","notFoundMessage":"No bucket list items found!"},on:{"onDelete":function (item) { return (_vm.deletableItem = {
              id: item.id,
              type: 'bucket',
              value: item.value
            }); }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }