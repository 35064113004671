<template>
  <md-icon class="add-icon"
    >{{ $icons.add }}<md-tooltip>{{ toolTip }}</md-tooltip></md-icon
  >
</template>
<script>
export default {
  name: "add-icon",
  props: {
    toolTip: {
      type: String,
      default: "Add"
    }
  }
};
</script>
