export default {
  get(key) {
    return localStorage.getItem(key);
  },
  set(key, value) {
    localStorage.setItem(key, value);
  },
  setBoolean(key, value) {
    this.set(key, value ? "1" : "0");
  },
  getBoolean(key) {
    const result = this.get(key);
    return result && result === "1";
  },
  remove(key) {
    localStorage.removeItem(key);
  },
  clear() {
    localStorage.clear();
  }
};
