<template>
  <main-app-container
    title="Organisations"
    add_text="New organisation"
    :icon="$icons.organisation"
    searchLabel="Search organisations"
    @onSearchChange="onOrganisationSearch"
    @addItem="onAddClicked()"
  >
    <cb-empty-state
      v-if="list.length === 0 && !loading"
      :icon="$icons.organisation"
      label="Create first organisation"
      description="To start working around one must add a organisation."
      button="Create first organisation"
      @addItem="onAddClicked()"
    />
    <form-errors :resultError="resultError" />
    <md-card v-if="list.length > 0">
      <md-card-content>
        <organisation-list :organisations="list" />
        <md-card-actions>
          <md-button
            :disabled="!paginator.hasPrevious"
            v-on:click="onPreviousClick"
            >Previous</md-button
          >
          <md-button :disabled="!paginator.hasNext" v-on:click="onNextClick"
            >Next</md-button
          >
        </md-card-actions>
      </md-card-content>
    </md-card>
  </main-app-container>
</template>
<script>
import { OrganisationList } from "@/components/Organisation/";
import OrganisationService from "@/services/OrganisationService";

export default {
  name: "organisations-page",
  components: {
    OrganisationList
  },
  data() {
    return {
      loading: true,
      list: [],
      resultError: null,
      paginator: { hasNext: false, hasPrevious: false },
      organisationListIterator: null
    };
  },
  methods: {
    onAddClicked: function() {
      this.$router.push({ name: "New Organisation" });
    },
    checkListPaginator: async function() {
      const hasNext = await this.organisationListIterator.hasNext();
      const hasPrevious = await this.organisationListIterator.hasPrevious();
      this.paginator = { hasNext: hasNext, hasPrevious: hasPrevious };
    },
    onOrganisationSearch: async function(searchQuery) {
      this.paginator = { hasNext: false, hasPrevious: false };
      this.loading = true;
      try {
        await this.organisationListIterator.setQueryParameter(
          "query",
          searchQuery
        );
        await this.onNextClick();
      } catch (error) {
        console.log(error);
        this.resultError = error;
      } finally {
        this.loading = false;
      }
    },
    onAddItem: function() {
      this.$router.push({
        name: "Add User"
      });
    },
    onPreviousClick: async function() {
      this.paginator = { hasNext: false, hasPrevious: false };
      this.loading = true;
      try {
        this.list = await this.organisationListIterator.getPrevious();
        await this.checkListPaginator();
      } catch (error) {
        this.resultError = error;
      } finally {
        this.loading = false;
      }
    },
    onNextClick: async function() {
      this.paginator = { hasNext: false, hasPrevious: false };
      this.loading = true;
      try {
        this.list = await this.organisationListIterator.getNext();
        await this.checkListPaginator();
      } catch (error) {
        this.resultError = error;
      } finally {
        this.loading = false;
      }
    }
  },
  created: async function() {
    this.list = [];
    this.loading = true;
    try {
      this.organisationListIterator = OrganisationService.getOrganisationListIterator();
      await this.onNextClick();
    } catch (error) {
      this.resultError = error;
    } finally {
      this.loading = false;
    }
  }
};
</script>
