import Api from "./api/RestAPI";

export default {
  getYourself() {
    return Api.get("/api/user/profile/v1");
  },
  saveYourself(data) {
    return Api.post("/api/user/profile/v1", data);
  },
  saveYourImage(image) {
    return Api.post("/api/user/profile/v1/image", { image: image });
  },
  getYourImages: function() {
    return Api.getImage(`/api/user/profile/v1/images`);
  },
  getFullImageUrl: async function() {
    const images = await this.getYourImages();
    if (images && images.full && images.full.url) {
      return images.full.url;
    }
  },
  getThumbnailImageUrl: async function() {
    const images = await this.getYourImages();
    if (images && images.thumbnail && images.thumbnail.url) {
      return images.thumbnail.url;
    }
  }
};
