<template>
  <md-table class="user-account-add" v-if="account != null">
    <md-table-row slot="md-table-row">
      <md-table-cell v-if="account.type === 'Email'"
        ><account-type-email-icon
      /></md-table-cell>
      <md-table-cell v-if="account.type === 'PhoneNumber'"
        ><account-type-phone-icon
      /></md-table-cell>

      <md-table-cell v-if="account.type === 'Email'" width="100%"
        ><md-field>
          <label>Enter e-mail</label>
          <md-input v-model="account.value" type="email" required /> </md-field
      ></md-table-cell>
      <md-table-cell v-if="account.type === 'PhoneNumber'" width="100%"
        ><md-field>
          <label>Enter phone number</label>
          <md-input v-model="account.value" required /> </md-field
      ></md-table-cell>
      <md-table-cell>
        <md-button class="md-icon-button" @click="accountDone">
          <md-icon>{{ $icons.done }}</md-icon>
        </md-button></md-table-cell
      >
    </md-table-row>
  </md-table>
</template>
<script>
import AccountTypeEmailIcon from "./AccountTypeEmailIcon";
import AccountTypePhoneIcon from "./AccountTypePhoneIcon";
import Vue from "vue";

Vue.component(AccountTypeEmailIcon.name, AccountTypeEmailIcon);
Vue.component(AccountTypePhoneIcon.name, AccountTypePhoneIcon);

export default {
  name: "user-add-account",
  props: {
    account: Object
  },
  methods: {
    accountDone: function() {
      if (this.account.value == null || this.account.value.trim() == "") return;
      this.$emit(
        "accountChange",
        this.account.index,
        this.account.type,
        this.account.value
      );
    }
  }
};
</script>
