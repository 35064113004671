import Vue from "vue";

import GroupIcon from "./GroupIcon";
import DeleteIcon from "./DeleteIcon";
import MenuIcon from "./MenuIcon";
import LogoutIcon from "./LogoutIcon";
import DrawerCloseIcon from "./DrawerCloseIcon";
import AddIcon from "./AddIcon";
import HomeIcon from "./HomeIcon";
import UsersIcon from "./UsersIcon";
import OrganisationIcon from "./OrganisationIcon";
import GroupUsersIcon from "./GroupUsersIcon";
import MenuDropDownIcon from "./MenuDropDownIcon";
import UserProfileEditIcon from "./UserProfileEditIcon";
import OrganisationUsersIcon from "./OrganisationUsersIcon";
import AutoQuestionsIcon from "./AutoQuestionsIcon";
import DoneIcon from "./DoneIcon";

Vue.prototype.$icons = {
  delete: "delete",
  group: "account_tree",
  menu: "menu",
  logout: "logout",
  drawerClose: "keyboard_arrow_left",
  home: "home",
  users: "people",
  organisation: "business",
  membership: "device_hub",
  groupUsers: "people_alt",
  organisationUsers: "people_alt",
  menuDropDown: "more_vert",
  add: "add",
  done: "done",
  user_profile_edit: "person_pin",
  lists: "subject",
  features: "rule",
  checkin: "mobile_friendly",
  security: "security",
  account_status: {
    superuser: "local_police",
    //superuser: "supervisor_account",
    administrator: "person_pin",
    teacher: "school",
    support: "engineering",
    lead: "military_tech",
    staff: "emoji_people",
    //moderator: "person_outline",
    member: "person"
  },
  autoQuestions: "quiz",
  account_type: {
    email: "email",
    phone: "phone"
  },
  getAccountStatusIconByRole: function(role) {
    if (!role) {
      return this.account_status.member;
    }
    switch (role) {
      case "ROLE_SUPERUSER":
        return this.account_status.superuser;
      case "ROLE_ADMINISTRATOR":
        return this.account_status.administrator;
      case "ROLE_TEACHER":
        return this.account_status.teacher;
      case "ROLE_STAFF":
        return this.account_status.staff;
      case "ROLE_LEAD":
        return this.account_status.lead;
      case "ROLE_SUPPORT":
        return this.account_status.support;
      case "ROLE_MEMBER":
        return this.account_status.member;
      case "ROLE_MODERATOR":
        return "person_off";
      default:
        return this.account_status.member;
    }
  }
};

Vue.component(GroupIcon.name, GroupIcon);
Vue.component(DeleteIcon.name, DeleteIcon);
Vue.component(MenuIcon.name, MenuIcon);
Vue.component(LogoutIcon.name, LogoutIcon);
Vue.component(DrawerCloseIcon.name, DrawerCloseIcon);
Vue.component(AddIcon.name, AddIcon);
Vue.component(HomeIcon.name, HomeIcon);
Vue.component(UsersIcon.name, UsersIcon);
Vue.component(OrganisationIcon.name, OrganisationIcon);
Vue.component(GroupUsersIcon.name, GroupUsersIcon);
Vue.component(OrganisationUsersIcon.name, OrganisationUsersIcon);
Vue.component(MenuDropDownIcon.name, MenuDropDownIcon);
Vue.component(UserProfileEditIcon.name, UserProfileEditIcon);
Vue.component(AutoQuestionsIcon.name, AutoQuestionsIcon);
Vue.component(DoneIcon.name, DoneIcon);
