<template>
  <md-icon class="logout-icon"
    >{{ $icons.account_type.email
    }}<md-tooltip>{{ toolTip }}</md-tooltip></md-icon
  >
</template>
<script>
export default {
  name: "account-type-email-icon",
  props: {
    toolTip: {
      type: String,
      default: "E-mail"
    }
  }
};
</script>
