import Firebase_dev from "./Firebase_dev";
import Firebase from "./Firebase";

var endpoints = null;

if (
  process.env.VUE_APP_MOCK_FIREBASE &&
  process.env.VUE_APP_MOCK_FIREBASE === "true"
) {
  require("./Firebase_dev.js");
  endpoints = Firebase_dev;
} else {
  require("./Firebase.js");
  endpoints = Firebase;
}

export default endpoints;
