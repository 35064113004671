<template>
  <main-app-container
    :title="titleName"
    :icon="$icons.group"
    :organisation="organisation"
    :group="edit"
  >
    <md-progress-bar
      class="md-accent"
      md-mode="indeterminate"
      v-if="isLoading"
    ></md-progress-bar>
    <form-errors :resultError="resultError" />
    <md-card v-if="!isLoading">
      <md-card-header>
        <md-card-header-text>
          <created-at-in-list :item="edit" :show-prefix="true" />
          <div class="md-title"></div>
        </md-card-header-text>
        <md-menu md-size="big" md-direction="bottom-end">
          <md-button class="md-icon-button" md-menu-trigger>
            <menu-dropdown-icon />
          </md-button>

          <md-menu-content>
            <organisation-menu-item :organisation="organisation" />
            <organisation-user-list-menu-item :organisation="organisation" />
            <group-list-menu-item :organisation="organisation" />
          </md-menu-content>
        </md-menu>
      </md-card-header>

      <md-card-content class="md-primary">
        <group-edit
          :edit="edit"
          :groupTypes="groupTypes"
          @onStructureTypeChange="onStructureTypeChange"
        />
      </md-card-content>
      <md-card-actions md-alignment="right">
        <group-user-list-button :group="edit" />
        <md-button @click="onSave()" v-if="edit.id == null">Save</md-button>
        <md-button @click="onSave()" v-else>Update</md-button>
      </md-card-actions>
    </md-card>
    <hide-show-card
      v-if="groupOrgMembershipTitle != null"
      :title="groupOrgMembershipTitle"
    >
      <md-card-header>
        <md-card-header-text v-if="edit.partOfOrganisation">
          <p>
            This group is part of the organisation. Pressing the button below
            puts it into the sandbox so no one from this group can see other
            group in the organisation he does not directly belong to.
          </p>
        </md-card-header-text>
        <md-card-header-text v-else>
          <p>
            This group is not part of the organisation. No one from this group
            can see other group in the organisation he does not directly belong
            to.
          </p>
          <p>Pressing the button below makes it part of the organisation.</p>
        </md-card-header-text>
      </md-card-header>

      <md-card-actions>
        <md-button @click="showOrHideGroup()" v-if="edit.partOfOrganisation"
          >Unlink from organisation</md-button
        >
        <md-button @click="showOrHideGroup()" v-else
          >Make part of organisation</md-button
        >
      </md-card-actions>
    </hide-show-card>

    <hide-show-card
      v-if="edit.id != null && !isLoading"
      title="Invitations"
      id="invitations"
    >
      <md-card-header>
        <md-card-header-text>
          <p>Delete old invitation code before creating new one.</p>
        </md-card-header-text>
      </md-card-header>
      <md-card-content>
        <md-subheader v-if="invitations.length === 0"
          >No Invitations</md-subheader
        >
        <group-invitations
          @onDelete="onInvitationDelete"
          v-if="invitations.length > 0"
          :invitations="invitations"
        />
      </md-card-content>
      <md-card-actions md-alignment="right">
        <div class="md-layout md-gutter" v-if="canChangeHashHealthValue">
          <div class="md-layout-item" v-if="hashHealth !== '8'">
            <md-icon>notification_important</md-icon>
            <label
              >it is dangerous to leave the hash unmaintained for more than 8
              hours!</label
            >
          </div>
          <md-field class="md-layout-item">
            <label>Hash lifespan</label>
            <md-select
              v-model="hashHealth"
              name="hashHealthSelect"
              id="hashHealthSelect"
            >
              <md-option value="8">8 hours</md-option>
              <md-option value="24">24 hours</md-option>
              <md-option value="72">3 days</md-option>
              <md-option value="168">7 days</md-option>
              <md-option value="720">30 days</md-option>
              <md-option value="1440">60 days</md-option>
            </md-select>
          </md-field>
        </div>
        <md-button @click="generateInvitation()">Generate</md-button>
      </md-card-actions>
    </hide-show-card>
    <CheckInItems classifier="group" :id="edit.id" />
    <feature-list classifier="group" :id="edit.id" />
  </main-app-container>
</template>
<script>
import { GroupEdit } from "@/components/Group/";
import OrganisationService from "@/services/OrganisationService";
import GroupService from "@/services/GroupService";

import GroupInvitationService from "@/services/GroupInvitationService";
import GroupInvitations from "@/components/Group/GroupInvitations";
import FeatureList from "../../components/Feature/FeatureLongTypeList";
import RestAPI from "../../services/api/RestAPI";
import HideShowCard from "@/components/Layout/HideShowCard";
import CreatedAtInList from "@/components/Layout/CreatedAndUpdatedList";
import CheckInItems from "@/components/CheckIn/CheckInItems";

export default {
  name: "group-page",
  props: ["organisation_id", "group_id"],
  components: {
    CheckInItems,
    CreatedAtInList,
    FeatureList,
    GroupEdit,
    GroupInvitations,
    HideShowCard
  },
  data() {
    return {
      edit: {},
      groupTypes: [{ id: "CLASS", label: "Class" }],
      canChangeHashHealthValue: false,
      hashHealthValue: "8",
      resultError: null,
      organisation: {},
      invitations: [],
      organisationTabOpen: { status: false }
    };
  },
  computed: {
    groupOrgMembershipTitle() {
      if (
        !this.edit ||
        !this.edit.id ||
        this.isLoading ||
        !this.organisation ||
        !this.organisation.name
      ) {
        return null;
      }
      if (this.edit.partOfOrganisation) {
        return `This group is part of the ${this.organisation.name}`;
      }
      return `This group is not part of the ${this.organisation.name}`;
    },
    hashHealth: {
      get: function() {
        return this.hashHealthValue;
      },
      set: function(val) {
        if (!val) {
          return;
        }
        this.hashHealthValue = val;
      }
    },
    isLoading() {
      return !this.organisation.id;
    },
    titleName: function() {
      if (
        this.organisation &&
        this.organisation.name &&
        this.edit &&
        !this.edit.id
      ) {
        return `Create new group in organisation "${this.organisation.name}"`;
      } else if (
        this.organisation &&
        this.organisation.name &&
        this.edit &&
        this.edit.id
      ) {
        return `${this.organisation.name} / ${this.edit.name}`;
      } else {
        return "Loading...";
      }
    }
  },
  methods: {
    showOrHideGroup: async function() {
      if (!this.edit.id) return;
      try {
        const result = await GroupService.showOrHide(
          this.edit.id,
          !this.edit.partOfOrganisation
        );
        this.edit = result.result;
      } catch (e) {
        this.resultError = e;
      }
    },
    onInvitationDelete: async function(item, index) {
      try {
        await GroupInvitationService.delete(this.edit.id, item.id);
        this.invitations.splice(index, 1);
      } catch (e) {
        this.resultError = e;
      }
    },
    generateInvitation: async function() {
      try {
        const now = new Date();
        now.setHours(now.getHours() + Number(this.hashHealthValue));
        const result = await GroupInvitationService.create(
          this.edit.id,
          now.toISOString()
        );
        this.invitations.push(result.result);
      } catch (e) {
        this.resultError = e;
      }
    },
    onStructureTypeChange: function(type) {
      this.edit.type = type;
    },
    onSave: async function() {
      this.resultError = null;
      try {
        const result = await GroupService.save(this.edit.id, this.edit);
        if (this.edit.id == null) {
          this.edit = result.result;
          this.$router.push({
            name: "Edit Group",
            params: {
              organisation_id: result.result.organisationId,
              group_id: result.result.id
            }
          });
        } else {
          this.edit = result.result;
        }
      } catch (e) {
        this.resultError = e;
      }
    }
  },
  created: async function() {
    this.canChangeHashHealthValue =
      RestAPI.hasRole("ADMINISTRATOR") || RestAPI.hasRole("SUPERUSER");
    this.edit = { type: this.groupTypes[0].id };
    if (this.group_id) {
      try {
        const groupResult = await GroupService.get(this.group_id);
        const organisationResult = await OrganisationService.get(
          groupResult.result.organisationId
        );
        const groupInvitations = await GroupInvitationService.list(
          this.group_id
        );

        this.invitations = groupInvitations.result;
        this.edit = groupResult.result;
        this.organisation = organisationResult.result;
      } catch (error) {
        this.resultError = error;
      }
    } else if (this.organisation_id) {
      try {
        const organisationResult = await OrganisationService.get(
          this.organisation_id
        );
        this.organisation = organisationResult.result;
        this.edit.organisationId = organisationResult.result.id;
      } catch (error) {
        this.resultError = error;
      }
    }
  }
};
</script>
