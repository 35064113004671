<template>
  <main-app-container
    :title="titleName"
    @onSave="onSave"
    :icon="$icons.users"
    :organisation="organisation"
    :group="group"
  >
    <form-errors :resultError="resultError" />
    <UserMainActions
      v-if="this.edit.id"
      :user="edit"
      :onDelete="onDeleteUser"
      :onDisable="onDisableUser"
    />
    <HideShowCard
      v-if="edit.language"
      id="languages"
      :title="`User language: ${edit.language.label}`"
      :keep-it-close="true"
      open-icon="edit"
      v-on:onClose="languageSet = null"
    >
      <md-card-content class="md-gutter">
        <languages-choice
          :language="edit.language"
          @onLanguagesChanged="item => (languageSet = item)"
        />
        <md-card-actions>
          <md-button
            :disabled="
              !languageSet ||
                (languageSet && !edit.language) ||
                languageSet.key === edit.language.key
            "
            @click="changeLanguage()"
            >Save language</md-button
          >
        </md-card-actions>
      </md-card-content>
    </HideShowCard>
    <user-profile-view :profile="edit.profile" />
    <user-list-accounts
      :accounts="edit.accounts"
      @changeAccount="changeAccount"
      @addAccount="addAccount"
    />
    <user-membership :user-id="edit.id" v-if="user_id"></user-membership>
    <feature-list classifier="user" :id="user_id" v-if="user_id" />
  </main-app-container>
</template>
<script>
import UserService from "@/services/UserService";
import UserListAccounts from "@/components/User/Account/UserListAccounts";
import UserProfileView from "@/components/User/UserProfileView";
import UserMainActions from "@/components/User/UserMainActions";
import FeatureList from "../../../components/Feature/FeatureLongTypeList";
import UserMembership from "@/components/User/membership/UserMembershipComponent";
import LanguagesChoice from "@/components/Layout/LanguagesChoice";
import HideShowCard from "@/components/Layout/HideShowCard";

export default {
  name: "user-edit-component",
  props: ["user_id", "group", "organisation"],
  components: {
    LanguagesChoice,
    UserMembership,
    FeatureList,
    UserListAccounts,
    UserProfileView,
    UserMainActions,
    HideShowCard
  },
  data() {
    return {
      edit: { accounts: [], roles: [], canChange: true },
      organisations: [],
      otherOrganisation: {
        visible: false,
        list: [],
        visibleList: []
      },
      resultError: null,
      languageSet: null
    };
  },
  computed: {
    titleName: function() {
      if (!this.edit) {
        return "Loading...";
      } else if (!this.edit.id && this.group && this.group.name) {
        return `Create user in group ${this.group.name}`;
      } else if (!this.edit.id && this.organisation && this.organisation.name) {
        return `Create user in organisation "${this.organisation.name}"`;
      } else if (!this.edit.id) {
        return "Create user";
      } else if (
        this.edit.roles &&
        this.edit.roles.indexOf("ROLE_SUPERUSER") >= 0
      ) {
        return "Edit super user";
      } else {
        return "Edit user";
      }
    }
  },
  methods: {
    changeLanguage: async function() {
      if (!this.languageSet) {
        return;
      }
      const result = await UserService.setUserLanguage(
        this.edit.id,
        this.languageSet.key
      );
      this.edit.language = result.result;
      this.languageSet = null;
    },
    onDeleteUser: function() {
      UserService.delete(this.edit.id)
        .then(result => {
          this.setUser(result.result);
        })
        .catch(error => {
          this.resultError = error;
        });
    },
    onDisableUser: function() {
      UserService.disable(this.edit.id)
        .then(result => {
          this.setUser(result.result);
        })
        .catch(error => {
          this.resultError = error;
        });
    },
    changeAccount: function(index, type, value) {
      console.log("CHANGE: ", index, type, value);
    },
    addAccount: async function(type, value) {
      if (!type || !value || !type.trim() || !value.trim()) {
        return;
      }
      try {
        const result = await UserService.createUser({
          userId: this.edit ? this.edit.id : null,
          type: type,
          value: value,
          organisationId: this.organisation ? this.organisation.id : null,
          groupId: this.group ? this.group : null
        });
        if (!this.user_id && result.groupId) {
          return this.$router.replace({
            name: "Edit Group User",
            params: { group_id: result.groupId, user_id: result.userId }
          });
        } else if (!this.user_id && result.organisationId) {
          return this.$router.replace({
            name: "Edit Organisation User",
            params: {
              organisation_id: result.organisationId,
              user_id: result.userId
            }
          });
        } else if (!this.user_id) {
          return this.$router.replace({
            name: "Edit User",
            params: { user_id: result.userId }
          });
        }
        this.edit = result.result;
        //console.log(this.edit);
      } catch (resultError) {
        this.resultError = resultError;
      }
    },

    onSave: function() {
      this.resultError = null;
    },
    setUser: async function(newUser) {
      this.edit = newUser;
      this.edit.canChange = !!this.edit.deletedAt;
    },
    fetchData: async function() {
      this.edit = { accounts: [] };
      this.resultError = null;
      if (this.user_id == null) {
        return;
      }
      try {
        const result = await UserService.get(this.user_id);
        await this.setUser(result.result);
      } catch (e) {
        this.resultError = e;
      }
    }
  },
  created: function() {
    this.fetchData();
  },
  watch: {
    $route: "fetchData"
  }
};
</script>
