<template>
  <div class="md-layout md-gutter">
    <div
      class="md-layout-item md-alignment-top-left md-medium-size-60 md-small-size-60 md-xsmall-size-100"
    >
      <md-field>
        <label>Group name</label>
        <md-input v-model="edit.name" />
      </md-field>
      <StructureTypeChoice
        structure-key="group"
        :value="edit.type"
        @onStructureTypeChange="item => $emit('onStructureTypeChange', item)"
      />
    </div>
    <div
      class="md-layout-item md-alignment-top-left md-medium-size-33 md-small-size-50 md-xsmall-size-100"
    >
      <md-datepicker v-model="edit.trialUntil">
        <label>Trial end date</label>
      </md-datepicker>
      <md-datepicker v-model="edit.paidUntil">
        <label>Paid until date</label>
      </md-datepicker>
    </div>
  </div>
</template>
<script>
import StructureTypeChoice from "@/components/Layout/StructureTypeChoice";
export default {
  name: "group-edit",
  components: { StructureTypeChoice },
  props: ["edit"]
};
</script>
