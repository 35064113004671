<template>
  <main-app-container title="Security management" :icon="$icons.security">
    <cb-empty-state
      v-if="
        apiKeys.length === 0 &&
          Object.keys(this.scopes).length === 0 &&
          !loading
      "
      :icon="$icons.security"
      label="No tokens generated"
      description="No tokens generated and can not be generated as there are no scopes defined"
    />
    <api-key-list :keys="apiKeys" @onDelete="onDeleteApiKey" />
    <api-key-generation :scopes="scopes" :onGenerate="onGenerate" />
    <api-key-token-show :token="token" :onTokenClear="onApiTokenClear" />
  </main-app-container>
</template>
<script>
import {
  ApiKeyList,
  ApiKeyGeneration,
  ApiKeyTokenShow
} from "@/components/ApiKey";
import ApiKeyService from "@/services/ApiKeyService";

export default {
  name: "security-management",
  components: {
    ApiKeyGeneration,
    ApiKeyList,
    ApiKeyTokenShow
  },
  data() {
    return {
      loading: true,
      apiKeys: [],
      token: null,
      scopes: {},
      resultError: null
    };
  },
  methods: {
    onApiTokenClear: function() {
      this.token = null;
    },
    onDeleteApiKey: function(apiKey, index) {
      ApiKeyService.deleteApiKey(apiKey.id)
        .then(() => {
          if (
            this.token &&
            this.apiKeys[this.apiKeys.length - 1].id === apiKey.id
          ) {
            this.token = null;
          }
          this.apiKeys.splice(index, 1);
        })
        .catch(error => {
          this.resultError = error;
        });
    },
    onGenerate: function(scopes) {
      ApiKeyService.generateApiKey(scopes)
        .then(result => {
          this.token = `Bearer ${result.result.token}`;
          this.apiKeys.push(result.result);
        })
        .catch(error => {
          this.resultError = error;
        });
    }
  },
  created: function() {
    this.list = [];
    ApiKeyService.list()
      .then(result => {
        this.apiKeys = result.result;
        this.loading = false;
      })
      .catch(error => {
        this.resultError = error;
      });
    ApiKeyService.getScopes()
      .then(result => {
        this.scopes = result.result;
        this.loading = false;
      })
      .catch(error => {
        this.resultError = error;
      });
  }
};
</script>
