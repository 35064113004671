<template lang="html">
  <div class="page-container">
    <md-card>
      <md-card-header>
        <md-card-header-text>
          <div class="md-title">Please Sign in</div>
        </md-card-header-text>
      </md-card-header>
      <md-card-content>
        <ActionError :message="errorMessage" />
        <div id="firebaseui-auth-container"></div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import Firebase from "@/services/api/firebase";
import AuthListener from "@/services/api/AuthListener";
import ActionError from "../components/Layout/Errors/ActionError";

export default {
  name: "auth",
  components: { ActionError },
  data() {
    return {
      errorMessage: null
    };
  },
  mounted() {
    var uiConfig = {
      signInSuccessUrl: "/",
      signInOptions: Firebase.signInOptions
    };
    Firebase.firebaseui.start("#firebaseui-auth-container", uiConfig);
  },
  created() {
    let self = this;
    AuthListener.onError(function(error) {
      if (error.status != 412 || error.error == null) return;
      self.errorMessage = error.error.message;
    });
  }
};
</script>
