<template>
  <router-view></router-view>
</template>

<script>
import RestAPI from "@/services/api/RestAPI";
import AuthListener from "@/services/api/AuthListener";

export default {
  data() {
    return {
      authenticated: false
    };
  },
  methods: {
    logout() {
      RestAPI.logout();
    }
  },
  created: function() {
    var _this = this;
    AuthListener.onChange(
      () => {
        _this.authenticated = true;
      },
      () => {
        _this.authenticated = false;
      }
    );
  },
  name: "app"
};
</script>
<style src="../node_modules/firebaseui/dist/firebaseui.css"></style>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,400italic");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
/*
@import "~vue-material/dist/theme/engine";

@include md-register-theme("default", (
primary: #200a33,
accent: rgb(245, 214, 11),
theme: dark
));

@import "~vue-material/dist/theme/all";
 */
@import "@/assets/scss/clanbeat.scss";
</style>
