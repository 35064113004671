import Api from "./api/RestAPI";

export default {
  listInOrganisations(organisation_id) {
    return Api.get(`/api/organisation/v1/${organisation_id}/groups`);
  },
  get(group_id) {
    return Api.get(`/api/group/v1/${group_id}`);
  },
  save(group_id, data) {
    if (group_id) {
      return Api.put(`/api/group/v1/${group_id}`, data);
    } else {
      return Api.post(
        `/api/organisation/v1/${data.organisationId}/group`,
        data
      );
    }
  },
  delete(group_id) {
    return Api.delete(`/api/group/v1/${group_id}`);
  },
  showOrHide(group_id, newStatus) {
    if (newStatus) {
      return Api.put(`/api/group/v1/${group_id}/organisation/verified`);
    } else {
      return Api.delete(`/api/group/v1/${group_id}/organisation/verified`);
    }
  }
};
