<template>
  <md-icon class="security-icon"
  >{{ $icons.security }}<md-tooltip>{{ toolTip }}</md-tooltip></md-icon
  >
</template>
<script>
export default {
  name: "security-icon",
  props: {
    toolTip: {
      type: String,
      default: "Security"
    }
  }
};
</script>
