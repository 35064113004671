import Firebase from "@/services/api/firebase";
import RequestWrapper from "./RequestWrapper";
import AuthListener from "./AuthListener";

const SCOPE_FEATURE_PREFIX = "FEATURE_";
const SCOPE_ROLE_PREFIX = "ROLE_";

var token = {
  type: null,
  value: null,
  isRefreshing: false
};

var features = [];
var roles = [];

var refreshToken = {
  expires: -1,
  value: null
};

function isExpired() {
  return refreshToken.expires <= new Date();
}

function isAuthorized() {
  return token.value != null;
}

function setResponse(response) {
  token.type = response.token_type;
  token.value = response.token;
  refreshToken.value = response.refreshToken;
  refreshToken.expires =
    (Math.round(new Date() / 1000) + response.expires) * 1000;

  if (response.scope === null) {
    features = [];
    roles = [];
  } else {
    features = response.scope
      .filter(s => s.startsWith(SCOPE_FEATURE_PREFIX))
      .map(s => s.substr(SCOPE_FEATURE_PREFIX.length));
    roles = response.scope
      .filter(s => s.startsWith(SCOPE_ROLE_PREFIX))
      .map(s => s.substr(SCOPE_ROLE_PREFIX.length));
  }
}

function withHeader(options) {
  if (!isAuthorized() || isExpired()) return options;
  options.headers = { Authorization: token.type + " " + token.value };
  return options;
}

function logout() {
  token.type = null;
  token.value = null;
  refreshToken.value = null;
  refreshToken.expires = null;
  AuthListener.fireChange(false);
}

Firebase.onAuthStateChanged((isLoggedIn, user) => {
  token.isRefreshing = true;
  if (!isLoggedIn) {
    token.isRefreshing = false;
    logout();
    return;
  }
  user
    .getIdTokenResult()
    .then(result => {
      RequestWrapper.doRequest({
        path: "/api/auth/v1",
        method: "POST",
        data: { token: result.token }
      })
        .then(response => {
          setResponse(response);
          AuthListener.fireChange(true);
          token.isRefreshing = false;
        })
        .catch(error => {
          AuthListener.fireChange(false, { error: error });
          token.isRefreshing = false;
        });
    })
    .catch(error => {
      AuthListener.fireChange(false, { error: error });
      token.isRefreshing = false;
    });
});

function callServer(path, method, data, resolve, reject) {
  if (token.isRefreshing) {
    setTimeout(() => {
      callServer(path, method, data, resolve, reject);
    }, 1);
    return;
  }
  var options = withHeader({ path: path, method: method });
  if (data) options.data = data;
  RequestWrapper.doRequest(options)
    .then(resolve)
    .catch(reject);
}

function doRequest(path, method, data) {
  if (!isAuthorized()) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        reject({ status: -1, message: "Unauthorized" });
      }, 1);
    });
  }
  if (isExpired()) {
    token.isRefreshing = true;
    return new Promise((resolve, reject) => {
      RequestWrapper.doRequest({
        path: "/api/auth/v1/refresh",
        method: "POST",
        data: { token: refreshToken.value }
      })
        .then(response => {
          setResponse(response);
          AuthListener.fireChange(true);
          token.isRefreshing = false;
          setTimeout(() => {
            callServer(path, method, data, resolve, reject);
          }, 1);
        })
        .catch(error => {
          token.isRefreshing = false;
          reject(error);
          AuthListener.fireChange(false, { error: error });
        });
    });
  }
  return new Promise((resolve, reject) => {
    callServer(path, method, data, resolve, reject);
  });
}

function logoutFirebase() {
  Firebase.logout((isSuccess, error) => {
    console.error(error);
    logout();
  });
  logout();
}

const imagesMap = {};

export default {
  logout: function() {
    callServer(
      "/api/auth/v1/logout",
      "POST",
      { token: refreshToken.value },
      function() {
        logoutFirebase();
      },
      function(error) {
        console.error(error);
        logoutFirebase();
      }
    );
  },
  getImage: async function(path) {
    const time = new Date().getTime();
    if (!imagesMap[path] || imagesMap[path].time < time) {
      imagesMap[path] = {
        time: time + 10000,
        path: imagesMap[path] ? imagesMap[path].path : null
      };
      try {
        const pathResult = await this.get(path);
        imagesMap[path] = { time: time + 10000, path: pathResult };
      } catch (e) {
        if (e.status !== 404) {
          throw e;
        }
      }
    }
    return imagesMap[path].path;
  },
  get: (path, data) => doRequest(path, "GET", data),
  post: (path, data) => doRequest(path, "POST", data),
  put: (path, data) => doRequest(path, "PUT", data),
  delete: (path, data) => doRequest(path, "DELETE", data),
  hasRole: role => roles.indexOf(role) > -1,
  hasFeature: feature => features.indexOf(feature) > -1
};
