<template>
  <md-icon class="group-users-icon"
    >{{ $icons.groupUsers }}<md-tooltip>{{ toolTip }}</md-tooltip></md-icon
  >
</template>
<script>
export default {
  name: "group-users-icon",
  props: {
    toolTip: {
      type: String,
      default: "Group users"
    }
  }
};
</script>
