import Vue from "vue";
import MenuItemGroup from "./MenuItemGroup";
import MenuItemGroupList from "./MenuItemGroupList";
import MenuItemOrganisation from "./MenuItemOrganisation";
import MenuItemGroupUserList from "./MenuItemGroupUserList";
import MenuItemOrganisationUserList from "./MenuItemOrganisationUserList";

Vue.component(MenuItemGroup.name, MenuItemGroup);
Vue.component(MenuItemGroupList.name, MenuItemGroupList);
Vue.component(MenuItemOrganisation.name, MenuItemOrganisation);
Vue.component(MenuItemGroupUserList.name, MenuItemGroupUserList);
Vue.component(MenuItemOrganisationUserList.name, MenuItemOrganisationUserList);
