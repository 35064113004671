<template>
  <md-list v-if="values.length > 0">
    <md-list-item v-for="(item, index) in values" :key="index">
      <div class="md-list-item-text">
        <span>{{ item.value }}</span>
        <p v-if="item.owner">
          Created by {{ item.owner.name }} at
          <show-date-time :date="item.createdAt" />
        </p>
      </div>
      <md-button
        v-if="item.id"
        class="md-icon-button md-list-action"
        @click="$emit('onDelete', item)"
      >
        <delete-icon />
      </md-button>
    </md-list-item>
  </md-list>
</template>

<script>
export default {
  name: "interest-list",
  props: ["values", "list-type"]
};
</script>
