<template>
  <md-button
    v-if="organisation != null && organisation.id != null"
    :to="{ name: 'List Groups', params: { organisation_id: organisation.id } }"
    >Groups</md-button
  >
</template>
<script>
export default { name: "group-list-button", props: ["organisation"] };
</script>
