<template>
  <div>
    <md-table v-model="organisations" class="item-list">
      <md-table-row
        slot="md-table-row"
        slot-scope="{ item, index }"
        v-on:click="goToOrganisation(item, index)"
      >
        <md-table-cell md-label="Type">{{ item.type }} </md-table-cell>
        <md-table-cell md-label="Name" width="100%">
          <div class="md-layout md-gutter">
            <img
              class="list-avatar"
              v-if="
                item.images &&
                  item.images.thumbnail &&
                  item.images.thumbnail.url
              "
              :src="item.images.thumbnail.url"
              alt="Avatar"
            />
            <span class="md-layout-item md-alignment-top-left"
              ><router-link :to="`/organisation/${item.id}`">{{
                item.name
              }}</router-link></span
            >
            <paid-and-trial-in-list :item="item"/>
            <created-at-in-list :item="item" />
          </div>
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import PaidAndTrialInList from "@/components/Layout/PaidAndTrialInList";
import CreatedAtInList from "@/components/Layout/CreatedAndUpdatedList";
export default {
  name: "user-list",
  components: { CreatedAtInList, PaidAndTrialInList },
  props: ["organisations"],
  methods: {
    goToOrganisation(item) {
      this.$router.push({
        name: "Edit Organisation",
        params: { organisation_id: item.id }
      });
    }
  }
};
</script>
