<template>
  <md-card>
    <md-toolbar>
      <div
        class="md-toolbar-section-start hide-show-card-title"
        v-on:click="onToolbarClick"
      >
        <md-button class="md-icon-button"
          ><md-icon v-if="isOpened.status">{{ closeIcon }}</md-icon
          ><md-icon v-else>{{ openIcon }}</md-icon></md-button
        >
        <div>{{ title }}</div>
      </div>
    </md-toolbar>
    <slot v-if="isOpened.status" />
  </md-card>
</template>
<style>
.hide-show-card-title {
  cursor: pointer;
}</style
>;
<script>
import StorageKeyValue from "@/services/StorageKeyValue";

export default {
  name: "hide-show-card",
  data: function() {
    let status = this.defaultOpen === true;
    let key = null;
    if (this.id) {
      key = `_cb_hs::${this.id}::${this.$route.matched[0].path}`;
      if (StorageKeyValue.get(key)) {
        status = StorageKeyValue.getBoolean(key);
      }
    }
    return {
      key: key,
      isOpened: { status: status }
    };
  },
  watch: {
    "isOpened.status": function(val) {
      this.$emit(val ? "onOpen" : "onClose");
      if (!this.key) return;
      StorageKeyValue.setBoolean(this.key, val);
    }
  },
  props: {
    title: {
      type: String
    },
    id: {
      type: String
    },
    closeIcon: {
      type: String,
      required: false,
      default() {
        return "keyboard_arrow_up";
      }
    },
    openIcon: {
      type: String,
      required: false,
      default() {
        return "keyboard_arrow_down";
      }
    },
    defaultOpen: {
      type: Boolean
    }
  },
  methods: {
    onToolbarClick() {
      this.isOpened.status = !this.isOpened.status;
    }
  }
};
</script>
