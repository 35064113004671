<template>
  <user-edit-component :user_id="user_id" />
</template>
<script>
import UserEditComponent from "./Shared/UserEditComponent";

export default {
  name: "user-edit-page",
  props: ["user_id"],
  components: {
    UserEditComponent
  }
};
</script>
