import Api from "./api/RestAPI";

const ROOT_ENDPOINT = "/api/checkin/v1";

const enableEndpoint = (endpoint, isEnabled) => {
  if (isEnabled) {
    return Api.put(`${endpoint}/enable`);
  }
  return Api.put(`${endpoint}/disable`);
};

export default {
  async findQuestionDefinitionForGroup(groupId) {
    return Api.get(`${ROOT_ENDPOINT}/group/${groupId}`);
  },
  async createQuestionDefinition(groupId, question) {
    return Api.post(`${ROOT_ENDPOINT}/group/${groupId}`, { value: question });
  },
  async changeQuestionDefinition(definitionId, question) {
    return Api.put(`${ROOT_ENDPOINT}/${definitionId}`, { value: question });
  },
  async enableQuestionDefinition(definitionId, isEnabled) {
    return enableEndpoint(`${ROOT_ENDPOINT}/${definitionId}`, isEnabled);
  },

  async removeQuestionDefinition(definitionId) {
    return Api.delete(`${ROOT_ENDPOINT}/${definitionId}`);
  },
  async setSchedulers(definitionId, schedulers) {
    return Api.post(`${ROOT_ENDPOINT}/${definitionId}`, schedulers);
  },
  async addSchedulers(definitionId, scheduler, dayOfWeek, time) {
    return Api.post(`${ROOT_ENDPOINT}/${definitionId}/schedulers`, {
      dayOfWeek: dayOfWeek,
      time: time
    });
  },
  async addScheduler(definitionId, dayOfWeek, when) {
    return Api.post(`${ROOT_ENDPOINT}/${definitionId}/scheduler`, {
      dayOfWeek: dayOfWeek,
      when: when
    });
  },
  async removeScheduler(definitionId, schedulerId) {
    return Api.delete(
      `${ROOT_ENDPOINT}/${definitionId}/scheduler/${schedulerId}`
    );
  },
  async enableScheduler(definitionId, schedulerId, isEnabled) {
    return enableEndpoint(
      `${ROOT_ENDPOINT}/${definitionId}/scheduler/${schedulerId}`,
      isEnabled
    );
  },
  async addQuestion(definitionId, question) {
    return Api.post(`${ROOT_ENDPOINT}/${definitionId}/question`, {
      value: question
    });
  },
  async changeQuestion(definitionId, quesitonId, question) {
    return Api.put(`${ROOT_ENDPOINT}/${definitionId}/question/${quesitonId}`, {
      value: question
    });
  },
  async removeQuestion(definitionId, quesitonId) {
    return Api.delete(
      `${ROOT_ENDPOINT}/${definitionId}/question/${quesitonId}`
    );
  },
  async enableQuestion(definitionId, quesitonId, isEnabled) {
    return enableEndpoint(
      `${ROOT_ENDPOINT}/${definitionId}/question/${quesitonId}`,
      isEnabled
    );
  }
};
