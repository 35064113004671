var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('md-table',{staticClass:"item-list",scopedSlots:_vm._u([{key:"md-table-row",fn:function(ref){
var item = ref.item;
var index = ref.index;
return _c('md-table-row',{on:{"click":function($event){return _vm.goToOrganisation(item, index)}}},[_c('md-table-cell',{attrs:{"md-label":"Type"}},[_vm._v(_vm._s(item.type)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Name","width":"100%"}},[_c('div',{staticClass:"md-layout md-gutter"},[(
              item.images &&
                item.images.thumbnail &&
                item.images.thumbnail.url
            )?_c('img',{staticClass:"list-avatar",attrs:{"src":item.images.thumbnail.url,"alt":"Avatar"}}):_vm._e(),_c('span',{staticClass:"md-layout-item md-alignment-top-left"},[_c('router-link',{attrs:{"to":("/organisation/" + (item.id))}},[_vm._v(_vm._s(item.name))])],1),_c('paid-and-trial-in-list',{attrs:{"item":item}}),_c('created-at-in-list',{attrs:{"item":item}})],1)])],1)}}]),model:{value:(_vm.organisations),callback:function ($$v) {_vm.organisations=$$v},expression:"organisations"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }