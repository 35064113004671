<template>
  <md-card v-if="keys.length > 0">
    <md-card-header>
      <md-card-header-text>
        <div class="md-title">
          Generated tokens
        </div>
      </md-card-header-text>
    </md-card-header>
    <md-card-content>
      <div>
        <md-table v-model="keys" md-sort="name" md-sort-order="asc">
          <md-table-row slot="md-table-row" slot-scope="{ item, index }">
            <md-table-cell md-label="Scopes" md-sort-by="scopes"
              >{{ item.scopes }}
            </md-table-cell>
            <md-table-cell md-label="Valid until" md-sort-by="valid"
              ><show-timestamp :date="item.validUntil" />
            </md-table-cell>
            <md-table-cell md-label="Creator" md-sort-by="creator"
              >{{ item.user.name }}
            </md-table-cell>
            <md-table-cell width="1%">
              <md-button
                class="md-icon-button"
                v-on:click="$emit('onDelete', item, index)"
              >
                <delete-icon />
              </md-button>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </div>
    </md-card-content>
  </md-card>
</template>

<script>
export default {
  name: "api-key-list",
  props: ["keys"]
};
</script>
