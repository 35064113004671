<template>
  <HideShowCard title="Accounts" id="accounts">
    <md-card-content class="md-gutter">
      <md-list>
        <md-list-item v-for="(account, index) in accounts" :key="index">
          <account-type-email-icon
            v-if="account.type === 'Email'"
          /><account-type-phone-icon v-if="account.type === 'PhoneNumber'" />
          <span class="md-list-item-text">{{ account.value }}</span>
        </md-list-item>
      </md-list>
      <user-add-account
        :account="editAccount"
        @accountChange="onAccountChange"
      />
    </md-card-content>
    <md-card-actions md-alignment="right">
      <md-button @click="addEmail">Add e-mail</md-button>
      <md-button @click="addPhone">Add phone</md-button>
    </md-card-actions>
  </HideShowCard>
</template>
<script>
import AccountTypeEmailIcon from "./AccountTypeEmailIcon";
import AccountTypePhoneIcon from "./AccountTypePhoneIcon";
import AddAccount from "./AddAccount";
import Vue from "vue";

Vue.component(AccountTypeEmailIcon.name, AccountTypeEmailIcon);
Vue.component(AccountTypePhoneIcon.name, AccountTypePhoneIcon);
Vue.component(AddAccount.name, AddAccount);
import HideShowCard from "@/components/Layout/HideShowCard";

export default {
  name: "user-edit-account",
  props: {
    accounts: {
      type: Array
    }
  },
  data() {
    return {
      editAccount: null
    };
  },
  components: {
    HideShowCard
  },
  methods: {
    setEditValue: function(index, type, value) {
      this.editAccount = null;
      Vue.nextTick().then(() => {
        this.editAccount = { type: type, value: value, index: index };
      });
    },
    setEditEmail: function(index, value) {
      this.setEditValue(index, "Email", value);
    },
    setEditPhoneNumber: function(index, value) {
      this.setEditValue(index, "PhoneNumber", value);
    },
    addEmail: function() {
      this.setEditEmail(null, null);
    },
    addPhone: function() {
      this.setEditPhoneNumber(null, null);
    },
    onAccountChange: function(index, type, value) {
      if (index == null) {
        this.$emit("addAccount", type, value);
      } else {
        this.$emit("changeAccount", index, type, value);
      }
      this.editAccount = null;
    }
  }
};
</script>
