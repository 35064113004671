<template>
  <md-card v-if="showContent">
    <md-toolbar>
      <h4 class="md-title" style="flex: 1">{{ title }}</h4>
      <md-radio v-model="visibility" value="all">All</md-radio>
      <md-radio v-model="visibility" value="created">Created</md-radio>
      <md-radio v-model="visibility" value="predefined">Predefined</md-radio>
    </md-toolbar>
    <md-card-content>
      <md-empty-state
        v-if="values.length === 0 && predefinedValues.length === 0"
        :md-icon="$icons.lists"
        :md-label="notFoundMessage"
      >
      </md-empty-state>
      <InterestList
        @onDelete="item => $emit('onDelete', item)"
        :values="values"
        class="md-double-line"
        v-if="visibility === 'all' || visibility === 'created'"
      />
      <InterestList
        :values="predefinedValues"
        v-if="visibility === 'all' || visibility === 'predefined'"
      />
    </md-card-content>
  </md-card>
</template>

<script>
import InterestList from "./InterestList.vue";

export default {
  name: "interests-container",
  props: [
    "values",
    "predefinedValues",
    "showContent",
    "title",
    "notFoundMessage"
  ],
  components: {
    InterestList
  },
  data() {
    return {
      visibility: "created",
      doDelete: undefined
    };
  }
};
</script>
